import { translate } from "locale/formatter";
import { PageWrapper } from "styles/AtomicStyles";

import { ImageIcons } from "components";
import Text from "components/Text";

const EmptyScreen = () => {
  return (
    <PageWrapper className="justify-center">
      <div className="flex justify-center items-center flex-col gap-2">
        <ImageIcons name="NoImage" className="w-40 h-40" />
        <Text>{translate("no_results_found")}</Text>
      </div>
    </PageWrapper>
  );
};

export default EmptyScreen;
