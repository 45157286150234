import { getStartOfToday } from "utils";

import { mandiService } from "./base";

export const dashboardStats = (mandi_id) => {
  return mandiService.get("mandistats", {
    params: {
      mandi_id,
      auction_date: getStartOfToday(),
    },
  });
};

export const getCompletedAuction = (params) => {
  return mandiService.get(`/auction_lot`, {
    params,
  });
};
