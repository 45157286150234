import { useEffect, useMemo, useState } from "react";
import { useTheme } from "@mui/styles";
import useMoEngage from "hooks/useMoEngage";
import { LOGIN_EVENTS } from "utils/events";

import { EVENT_NAMES, REGEX_DIGIT } from "./const";
import { CustomInput, style } from "./styled";

const OTPInput = ({
  otpLength = 6,
  verifyOtp = () => {},
  otpVerificationError = "",
  setOtpVerificationError = () => {},
}) => {
  const [otp, setOtp] = useState("");
  const { colors } = useTheme();
  const { otpGroup } = style();
  const tracker = useMoEngage();

  useEffect(() => {
    const shouldVerifyOtp = `${otp}`.trim().length === otpLength;
    if (shouldVerifyOtp) {
      verifyOtp(otp);
    } else if (!shouldVerifyOtp && otpVerificationError) {
      setOtpVerificationError("");
    }
  }, [otp, otpLength]);

  const otpDigits = useMemo(() => {
    const otpArray = otp.split("");
    const digits = [];
    for (let i = 0; i < otpLength; i += 1) {
      const digit = otpArray[i];
      if (REGEX_DIGIT.test(digit)) {
        digits.push(digit);
      } else {
        digits.push("");
      }
    }
    return digits;
  }, [otp, otpLength]);

  const focusOnNextELement = (target) => {
    const nextInputBox = target.nextElementSibling;

    nextInputBox?.focus();
  };

  const focusOnPreviousElement = (target) => {
    const previousInputBox = target.previousElementSibling;
    previousInputBox?.focus();
  };

  const onInputChange = (e, idx) => {
    const { target } = e;
    let targetValue = target.value.trim();
    const isValueDigit = REGEX_DIGIT.test(targetValue);

    const isAutofilled = targetValue.length > 1;

    const autoFilledValue = isAutofilled ? "Yes" : "No";

    tracker(LOGIN_EVENTS.OTP_ENTERED, { AutoFilled: autoFilledValue });

    if (!isValueDigit && targetValue !== "") {
      return;
    }
    const stringOtp = `${otp}`;
    targetValue = isValueDigit ? targetValue : " ";

    const targetValuelength = targetValue.length;

    if (targetValuelength) {
      const newValue =
        stringOtp.substring(0, idx) +
        targetValue +
        stringOtp.substring(idx + 1);

      setOtp(newValue);

      if (!isValueDigit) {
        return;
      }

      focusOnNextELement(target);
    } else if (targetValuelength === otpLength) {
      setOtp(targetValue);
      target.blur();
    }
  };

  const handleKeyDown = (e) => {
    const { target, key } = e;
    const targetValue = target.value;

    const { ARROW_LEFT, ARROW_RIGHT, BACKSPACE, ARROW_DOWN, ARROW_UP } =
      EVENT_NAMES;

    if ([ARROW_LEFT, ARROW_UP].includes(key)) {
      e.preventDefault();
      return focusOnPreviousElement(target);
    }

    if ([ARROW_RIGHT, ARROW_DOWN].includes(key)) {
      e.preventDefault();
      return focusOnNextELement(target);
    }

    target.setSelectionRange(0, targetValue.length);

    if (key !== BACKSPACE || targetValue !== "") {
      return;
    }

    focusOnPreviousElement(target);
  };

  const handleOnFocus = (e) => {
    const { target } = e;
    target.setSelectionRange(0, target.value.length);
  };

  const textColor = otpVerificationError ? colors.red : colors.primary;

  return (
    <div className={otpGroup} data-cy="login.enter_otp">
      {otpDigits.map((digit, idx) => (
        <CustomInput
          key={idx}
          type="text"
          inputMode="numeric"
          autoComplete="one-time-code"
          pattern="\d{1}"
          maxLength={otpLength}
          value={digit}
          colors={colors}
          onChange={(e) => onInputChange(e, idx)}
          onKeyDown={handleKeyDown}
          onFocus={handleOnFocus}
          textColor={textColor}
          id={`otp-${idx}`}
        />
      ))}
    </div>
  );
};

export default OTPInput;
