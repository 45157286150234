import { useEffect, useState } from "react";
import { getUnits } from "services/myBuys";

const useCustomerDetails = (
  me,
  setLoading = () => {},
  setVisibleCards = () => {}
) => {
  const [customerDetails, setCustomerDetails] = useState([]);

  useEffect(() => {
    if (!me || !me.customerId) return;

    const fetchData = async () => {
      setLoading(true);
      try {
        const { responseData } = await getUnits({ customer_id: me.customerId });
        const sortedMandi = responseData
          ?.sort((mandiA, mandiB) => mandiB.total_sale - mandiA.total_sale)
          ?.sort((mandiA, mandiB) =>
            mandiA.unit_status === mandiB.unit_status
              ? 0
              : mandiA.unit_status
              ? -1
              : 1
          );
        setCustomerDetails(sortedMandi);
        setVisibleCards(sortedMandi?.slice(0, 1) || []);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [me]);

  return [customerDetails];
};

export default useCustomerDetails;
