const DangerCircle = () => (
  <lottie-player
    src="https://assets7.lottiefiles.com/packages/lf20_4eo2289h.json"
    background="transparent"
    speed="1"
    style={{ width: "24px", height: "24px" }}
    loop
    autoplay
  />
);

export default DangerCircle;
