import React, { useState } from "react";
import PropTypes from "prop-types";

import { ImageIcons } from "components";

import { styles } from "./styled";

const Input = ({
  label,
  type,
  name,
  value,
  placeholder,
  onChange,
  onKeyDown,
  maxLength,
  className,
  style,
  error,
  disabled,
  invalidFeedbackCy,
  isPassword = false,
  inputStyles,
  ...others
}) => {
  const { inputLabel, inputContainer, inputField, eyeIcon } = styles();
  const [showPassWord, setShowPassword] = useState(false);
  const passwordIconName = showPassWord ? "EyeClose" : "EyeOpen";
  const inputType = isPassword && showPassWord ? "text" : type;
  const classes = styles({ inputStyles });

  return (
    <div className={`form-group ${className}`} style={style}>
      {label && <p className={inputLabel}>{label}</p>}
      <div className={inputContainer}>
        <input
          value={value}
          type={inputType}
          name={name}
          onChange={onChange}
          onKeyDown={onKeyDown}
          placeholder={placeholder}
          maxLength={maxLength}
          className={`input ${classes.inputField} ${
            error ? "form-control is-invalid" : "form-control"
          }`}
          disabled={disabled}
          {...others}
        />
        {isPassword && (
          <ImageIcons
            name={passwordIconName}
            alt="eye"
            className={eyeIcon}
            onClick={() => setShowPassword(!showPassWord)}
          />
        )}
        <div />
        <div className="invalid-feedback" data-id data-cy={invalidFeedbackCy}>
          {error}
        </div>
      </div>
    </div>
  );
};

Input.propTypes = {
  type: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.any, PropTypes.string]),
  name: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  maxLength: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.shape({}),
  error: PropTypes.string,
  disabled: PropTypes.bool,
};

Input.defaultProps = {
  type: "text",
  label: null,
  name: "",
  value: "",
  placeholder: "",
  onChange: () => {},
  onKeyDown: () => {},
  maxLength: "150",
  className: "",
  style: {},
  error: "",
  disabled: false,
};

export default Input;
