import { isEmpty } from "lodash";
import { sortByKey } from "utils";

export const getSoldTokens = (auctionLots, tokens) => {
  let soldTokens = 0;
  tokens.forEach((token) => {
    const lots = auctionLots[token];
    if (areAllLotsSold(lots)) soldTokens += 1;
  });
  return soldTokens;
};

export const areAllLotsSold = (lots = []) => {
  return lots.every(
    (lot) => (!!lot?.bidder_id && !!lot?.sold_price) || !!lot?.return_to_farmer
  );
};

export const getFirstTokenWithUnsoldLots = (tokens, lots) => {
  if (isEmpty(lots) || !Array.isArray(tokens)) return null;
  for (let i = 0; i < tokens.length; i += 1) {
    const token = tokens[i];
    const tokenLot = lots[token];
    if (!areAllLotsSold(tokenLot)) return tokens[i];
  }
  return null;
};

// this a function that maps lotid of a lot to the next lot to display in the sequencing
// at a given point in time by using mappedLots[lotId of any lot] we can receive the next lot to be auctioned
export const lotMapperManager = (tokens, lots) => {
  if (isEmpty(lots) || !Array(tokens)) return {};
  let mappedLots = {};
  let unmappedLot = null;
  for (let i = 0; i < tokens.length; i += 1) {
    const token = tokens[i];
    const tokenLots = sortByKey(lots[token], "grade")?.reverse();
    for (let j = 0; j < tokenLots?.length; j += 1) {
      const lot = tokenLots[j];
      if (unmappedLot) {
        mappedLots = {
          ...mappedLots,
          [unmappedLot.id]: lot,
        };
        unmappedLot = null;
      }
      if (j === tokenLots.length - 1) {
        unmappedLot = lot;
      } else {
        mappedLots = {
          ...mappedLots,
          [lot.id]: tokenLots[j + 1],
        };
      }
    }
  }
  return mappedLots;
};

// this function returns an array of tokens.
// first it pick up tokens that are sequenced, received from db
// after that it appends all other available tokens to auction in ascending sorted order
export const getTokens = (auctionLots, auctionTokens) => {
  let sequencedTokens = auctionTokens;
  const allTokens = Object.keys(auctionLots).sort((t1, t2) => t1 - t2) || [];

  const set = new Set(sequencedTokens);

  for (let i = 0; i < allTokens.length; i += 1) {
    const token = allTokens[i];
    if (!set.has(token)) {
      sequencedTokens = [...sequencedTokens, token];
    }
  }
  return sequencedTokens;
};
