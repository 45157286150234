import { lazy, useEffect, useState } from "react";

const Chart = lazy(() => import("react-apexcharts"));

const StepChart = ({
  chartData = [],
  showXAxisLabels = false,
  showYAxisLabels = false,
  yAxistitle = "",
  xAxistitle = "",
  lineColor = "",
}) => {
  const [data, setData] = useState([]);
  const [custOptions, setOptions] = useState({
    series: [
      {
        data,
      },
    ],
    options: {
      chart: {
        type: "area",
        toolbar: {
          show: false,
        },
      },
      tooltip: {
        enabled: false,
      },
      colors: [lineColor],
      stroke: {
        curve: "stepline",
      },
      dataLabels: {
        enabled: true,
        textAnchor: "middle",
        offsetX: 8,
        background: {
          padding: 8,
        },
        style: {
          fontSize: "24px",
        },
      },
      xaxis: {
        labels: {
          show: showXAxisLabels,
        },
        title: {
          text: xAxistitle,
        },
        max: 5 * ((data.length % 5) + 1),
      },
      yaxis: {
        labels: {
          show: showYAxisLabels,
        },
        title: {
          text: yAxistitle,
        },
        min: 0,
        max: 50,
      },
      markers: {
        hover: {
          sizeOffset: 4,
        },
      },
      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
      },
    },
  });
  useEffect(() => {
    setData(chartData);
  }, [chartData.length]);

  useEffect(() => {
    setOptions((opt) => {
      return {
        ...opt,
        series: [
          {
            data,
          },
        ],
        options: {
          ...opt.options,
          xaxis: {
            ...opt.xaxis,
            axisTicks: {
              show: false,
            },
            max: 5 * (data.length / 5 + 1),
          },
          yaxis: {
            ...opt.yaxis,
            min: Math.max(data[0], 0),
            max: Math.max(data[0] + 10, data[data.length - 1]),
            labels: {
              show: showYAxisLabels,
            },
          },
          dataLabels: {
            ...opt.dataLabels,
            formatter(val, opts) {
              const { dataPointIndex } = opts;
              return dataPointIndex === 0 || dataPointIndex === data.length - 1
                ? `₹${val}`
                : "";
            },
          },
        },
      };
    });
  }, [data.length]);

  return <Chart options={custOptions.options} series={custOptions.series} />;
};

export default StepChart;
