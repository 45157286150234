import { useEffect, useState } from "react";
import { useTheme } from "@mui/styles";
import useLatencyCalculator from "hooks/LatencyCalculator/useLatencyCalculator";
import useMoEngage from "hooks/useMoEngage";
import { translate } from "locale/formatter";
import { HOME_SCREEN_EVENTS } from "utils/events";

import Text from "components/Text";

import AverageBar from "./assets/average.png";
import OfflineBar from "./assets/offline.png";
import StrongBar from "./assets/strong.png";
import VeryWeakBar from "./assets/veryWeak.png";
import WeakBar from "./assets/weak.png";
import { NetWorkWrapper } from "./styled";
import { NETWORK_LATENCY } from "./utils";

const NetworkConfig = {
  strong: {
    icon: StrongBar,
    text: "strong",
  },
  average: {
    icon: AverageBar,
    text: "average",
  },
  weak: {
    icon: WeakBar,
    text: "weak",
  },
  veryWeak: {
    icon: VeryWeakBar,
    text: "very_weak",
  },
  offline: {
    icon: OfflineBar,
    text: "no_network",
  },
};

const NetworkBar = ({ showText }) => {
  const latency = useLatencyCalculator();
  const [networkStatus, setNetworkStatus] = useState(NetworkConfig.strong);

  const { colors } = useTheme();
  const tracker = useMoEngage();

  useEffect(() => {
    switch (true) {
      case latency <= NETWORK_LATENCY.STRONG:
        setNetworkStatus(NetworkConfig.strong);
        break;

      case latency > NETWORK_LATENCY.STRONG &&
        latency <= NETWORK_LATENCY.AVERAGE:
        setNetworkStatus(NetworkConfig.average);
        break;

      case latency > NETWORK_LATENCY.AVERAGE && latency <= NETWORK_LATENCY.WEAK:
        setNetworkStatus(NetworkConfig.weak);
        break;

      case latency > NETWORK_LATENCY.WEAK &&
        latency <= NETWORK_LATENCY.VERY_WEAK:
        setNetworkStatus(NetworkConfig.veryWeak);
        break;

      default:
        setNetworkStatus(NetworkConfig.veryWeak);
        break;
    }
  }, [latency]);

  return (
    <NetWorkWrapper
      showText
      colors={colors}
      data-cy="signal_bar"
      onClick={() => {
        tracker(HOME_SCREEN_EVENTS.NETWORK_STRENGTH_CLICK);
      }}
    >
      <img
        src={networkStatus.icon}
        alt={networkStatus.text}
        className="w-10 h-6"
      />
      {showText && (
        <Text textType="secondary" className="!font-bold">
          {translate(networkStatus.text)}
        </Text>
      )}
    </NetWorkWrapper>
  );
};

export default NetworkBar;
