/* eslint-disable no-return-assign */
import { useLocation } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import { translate } from "locale/formatter";
import { removeFruitX } from "utils";
import { currency } from "utils/currencyFormatter";
import { formatDate, getFormattedTime } from "utils/dateUtils";

import ConfirmationDialog from "components/Dialog/ConfirmationDialog";

import { classes } from "./styled";

const PaymentBreakup = ({
  open,
  handleClosePopUp,
  splitInfo,
  refNumber,
  transactionTime = "",
  paymentType = "",
}) => {
  const { state = "" } = useLocation();
  const { mandi_name: mandiName } = state || {};
  const { containerText } = classes();
  const { colors } = useTheme();

  const popupContent = (
    <>
      <Grid container className={containerText}>
        <Grid
          container
          className="flex px-2 "
          display={{ justifyContent: "space-between" }}
        >
          <Grid item>
            <b>{translate("total")}</b>
          </Grid>
          <Grid item>{currency(splitInfo[0]?.total_payment_made)}</Grid>
        </Grid>
      </Grid>
      {splitInfo.map(
        ({ mandi_credit_unit_name = "", amount_paid = 0 }, index) => {
          const isMandiNameMatched = mandiName === mandi_credit_unit_name;
          return (
            <Grid container key={index} className={containerText}>
              <Grid
                container
                className="flex p-2 w-[100%] rounded-lg"
                display={{ justifyContent: "space-between" }}
                style={{
                  color: isMandiNameMatched ? colors.darkGold : "inherit",
                  background: isMandiNameMatched ? colors.lightGold : "inherit",
                  fontWeight: isMandiNameMatched && "bold",
                }}
              >
                <Grid item>{removeFruitX(mandi_credit_unit_name)}</Grid>
                <Grid item>{currency(amount_paid)}</Grid>
              </Grid>
            </Grid>
          );
        }
      )}
    </>
  );

  const header = (
    <>
      <Grid
        container
        className="flex"
        display={{ justifyContent: "space-between", paddingBottom: ".5rem 0" }}
      >
        <Grid item>
          <Typography variant="subtitle1" fontSize="small">
            <b>
              {translate("ref_id")}: {refNumber || ""}
            </b>
          </Typography>
          <Typography variant="body2">
            {formatDate(transactionTime)} {getFormattedTime(transactionTime)}
          </Typography>
          <Typography variant="subtitle1" fontSize="small">
            {paymentType}
          </Typography>
        </Grid>
      </Grid>
      <hr />
    </>
  );

  return (
    <ConfirmationDialog
      open={open}
      header={header}
      content={popupContent}
      onCloseHandler={handleClosePopUp}
      primaryBtntext=""
      secondaryBtnText=""
    />
  );
};

export default PaymentBreakup;
