import { mandiService, velynkService } from "./base";

export const getMyBuys = (params) => {
  return mandiService.get(`/auction_lot`, {
    params,
  });
};

export const getMyPayments = (params) => {
  return velynkService.get("customer_mandis/get_mandi_payments.json", {
    params,
  });
};

export const getAllMyPayments = (params) => {
  return velynkService.get("cds/customer_ledger/customer_payments", { params });
};

export const getAllSplitPayments = (params) => {
  return velynkService.get("customer_mandis/get_all_spilts.json", {
    params,
  });
};

export const getUnits = (params) => {
  return mandiService.get(`getcustomermandicreditunit`, {
    params,
  });
};
