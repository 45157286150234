import { forwardRef } from "react";
import { Button } from "@mui/material";

import { getStyles, getVariant } from "./utils";

const Pills = forwardRef(
  (
    {
      children,
      type,
      style,
      size = "small",
      disabled,
      className,
      handleOnClick = () => {},
      ...props
    },
    ref
  ) => {
    return (
      <Button
        ref={ref}
        component="div"
        size={size}
        variant={getVariant(type)}
        style={getStyles(type, style)}
        disabled={disabled}
        className={className}
        onClick={handleOnClick}
        {...props}
      >
        {children}
      </Button>
    );
  }
);

export default Pills;
