import { textFormatter } from "./formatter";

const bold = (text) => {
  return <span className="!font-bold">{textFormatter(text)}</span>;
};

const italic = (text) => {
  return <span className="!italic">{textFormatter(text)}</span>;
};

const boldAndItalic = (text) => {
  return <span className="!font-bold !italic">{textFormatter(text)}</span>;
};

const underline = (text) => {
  return <span className="!underline">{textFormatter(text)}</span>;
};

const Header = ({ idx, text }) => {
  return (
    <div className="!mt-6 !font-bold">
      {idx}. {textFormatter(text)}
    </div>
  );
};

const Wrapper = ({ children }) => {
  return <div className="!mt-6">{children}</div>;
};

const point = (text, idx, values) => {
  return (
    <Wrapper>
      {idx ? `(${idx})` : ""} {textFormatter(text, values)}
    </Wrapper>
  );
};

const subpoint = (text, idx, values) => {
  return (
    <div className="!mt-6 !pl-2">
      {idx ? `(${idx})` : ""} {textFormatter(text, values)}
    </div>
  );
};

const Content = () => {
  return (
    <div>
      <div>
        {textFormatter("privacy_policy_description_1", {
          x1: (
            <span className="!font-bold !text-l">
              {textFormatter("privacy_policy_company_name")}
            </span>
          ),
          x2: bold("privacy_policy_referrer"),
          x3: bold("platform"),
          x4: bold("your"),
          x5: bold("you"),
        })}
      </div>
      <Wrapper>
        {textFormatter("privacy_policy_description_2", {
          x6: bold("privacy_policy"),
        })}
      </Wrapper>
      <div className="!mt-6">
        {textFormatter("privacy_policy_description_3", {
          x7: bold("services"),
        })}
      </div>
      <div className="!mt-6">
        {textFormatter("privacy_policy_description_4", {
          x8: boldAndItalic("i_accept"),
          x9: bold("you"),
          x10: bold("your"),
          x11: boldAndItalic("as_applicable"),
          x12: bold("terms"),
        })}
      </div>
      <div className="!mt-6">
        {textFormatter("privacy_policy_description_5")}
      </div>
      <div>
        <Header text="collection_of_information" idx={1} />
        <Wrapper>
          {textFormatter("collection_of_info_desc_1", {
            x13: italic("defined_below"),
          })}
          <Wrapper>
            {textFormatter(
              "this_privacy_law_applies_to_the_following_information"
            )}
            <div style={{ marginLeft: "1rem" }}>
              {point("collection_of_info_point_1", "1")}
              <div className="!mt-2">
                {textFormatter("in_addition_to_the_above_information")}
              </div>
              <div style={{ marginLeft: "1rem" }}>
                {subpoint("collection_of_info_subpoint_1", "1")}
                {subpoint("collection_of_info_subpoint_2", "", {
                  x14: bold("personal_information"),
                })}
                {subpoint("collection_of_info_subpoint_3")}
              </div>
              {point("collection_of_info_point_2", "2")}
              {point("collection_of_info_point_3", "3")}
              {point("collection_of_info_point_4", "4", {
                x15: underline("non_personal_and_autonatic_information"),
              })}
              {point("collection_of_info_point_5", "5")}
              {point("collection_of_info_point_6", "6")}
              {point("collection_of_info_point_7")}
              {point("collection_of_info_point_8")}
              {point("collection_of_info_point_9")}
              {point("collection_of_info_point_10")}
              {point("collection_of_info_point_11")}
              {point("collection_of_info_point_12")}
            </div>
          </Wrapper>
        </Wrapper>
      </div>
      <div>
        <Header text="use_of_information" idx={2} />
        <Wrapper>{textFormatter("use_of_info_desc_1")}</Wrapper>
        <Wrapper>{textFormatter("use_of_info_desc_2")}</Wrapper>
      </div>
      <div>
        <Header idx={3} text="sharing_of_information" />
        <Wrapper>{textFormatter("sharing_of_info_desc_1")}</Wrapper>
        <Wrapper>{textFormatter("sharing_of_info_desc_2")}</Wrapper>
        <Wrapper>{textFormatter("sharing_of_info_desc_3")}</Wrapper>
      </div>
      <div>
        <Header idx={4} text="security_precautions_and_measures" />
        <Wrapper>
          {textFormatter("security_precautions_and_measures_desc_1")}
        </Wrapper>
        <Wrapper>
          {textFormatter("security_precautions_and_measures_desc_2")}
        </Wrapper>
      </div>
      <div>
        <Header idx={5} text="links_to_third_party" />
        <Wrapper>
          {textFormatter("links_to_third_party_desc_1", {
            x16: bold("third_party_sites"),
          })}
        </Wrapper>
      </div>
      <div>
        <Header idx={6} text="public_posts" />
        <Wrapper>
          {textFormatter("public_posts_desc_1", {
            x17: bold("posts"),
            x18: italic("on_discussion_boards"),
          })}
        </Wrapper>
      </div>
      <div>
        <Header idx={7} text="policy_towards_children" />
        <Wrapper>{textFormatter("policy_towards_children_desc_1")}</Wrapper>
      </div>
      <div>
        <Header idx={8} text="your_consent_and_changes_to_privacy_policy" />
        <Wrapper>
          {textFormatter("your_consent_and_changes_to_privacy_policy_desc_1", {
            x19: bold("widthdraw_of_consent"),
          })}
        </Wrapper>
        <Wrapper>
          {textFormatter("your_consent_and_changes_to_privacy_policy_desc_2", {
            x20: bold("changes_to_our_privacy_policy"),
          })}
        </Wrapper>
      </div>
      <div>
        <Header idx={9} text="grievance_officer" />
        <Wrapper>{textFormatter("grievance_officer_desc_1")}</Wrapper>
        <Wrapper>
          {textFormatter("grievance_officer_desc_2", {
            x21: (
              <>
                {bold("name")}
                <strong>:</strong>
              </>
            ),
          })}
        </Wrapper>
        <div>
          {textFormatter("grievance_officer_desc_3", {
            x22: bold("email"),
          })}
        </div>
        <Wrapper>{textFormatter("grievance_officer_desc_4")}</Wrapper>
      </div>
      <div>
        <Header idx={10} text="questions" />
        <Wrapper>{textFormatter("questions_desc_1")}</Wrapper>
      </div>
    </div>
  );
};

export default Content;
