import { useSelector } from "react-redux";
import { logEvent } from "utils/firebaseUtils/firebase";

const useTracker = () => {
  const { me } = useSelector((state) => state.app);
  const { email } = me || {};

  const tracker = (event, params) => {
    if (process.env.NODE_ENV !== "production") {
      console.log(event, params);
    }
    if (!`${email}`.endsWith("@vegrow.in")) {
      logEvent(event, params);
    }
  };

  return tracker;
};

export default useTracker;
