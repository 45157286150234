import { networkStrengthParams } from "utils/const";
import { firestore } from "utils/firebaseUtils/firebase";
import {subscribeToDocs}  from "utils/firebaseUtils/hooks/useFirestore";

export const subscribeToPingResponses = (userId, sessionId, susbscriber) => {
  const pingsQuery = firestore
    .collection("users")
    .doc(userId)
    .collection("pings")
    .where("sessionId", "==", sessionId)
    .orderBy("initiatedAt", "desc")
    .limit(networkStrengthParams.numDocsPerSession);

    // console.log(userId, sessionId)

  return subscribeToDocs(pingsQuery, (pingsDocuments) => {
    return susbscriber(pingsDocuments);
  });
};
