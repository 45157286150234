import { Backdrop, CircularProgress } from "@mui/material";
import { useTheme } from "@mui/styles";

const CustomBackdrop = ({ open = false }) => {
  const { colors } = useTheme();
  return (
    <div>
      <Backdrop
        sx={{ color: colors.white, zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default CustomBackdrop;
