import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { BIDDER_ACCESS, path } from "utils/const";

import useMandiDetails from "./useMandiDetails";

const useAppAccess = () => {
  const { appAccessInfo } = useSelector((state) => state.auction);

  const previousInfo = useRef();

  const [mandiDetails, getMandiInfo] = useMandiDetails();

  const [isAccessUpdated, setIsAccessUpdated] = useState(false);

  const { bidderPlatform, catalogue, lotListing, wishlist } = path;

  const { id } = mandiDetails;

  useEffect(() => {
    const interval = setInterval(() => {
      if (!id) {
        getMandiInfo();
      } else clearInterval(interval);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const isInTheRightPath = (routeArr = []) => {
    const { pathname } = window.location;
    for (let i = 0; i < routeArr.length; i += 1) {
      const route = routeArr[i];
      if (pathname.includes(route.split(":")?.[0])) {
        return true;
      }
    }
    return false;
  };

  const accessUpdateHelper = () => {
    if (appAccessInfo[id] !== previousInfo.current?.[id]) {
      switch (appAccessInfo[id]) {
        case BIDDER_ACCESS.ENTER_AUCTION_RESTRICTION:
          return isInTheRightPath([bidderPlatform]);
        case BIDDER_ACCESS.COMPLETE_RESTRICTION:
          return isInTheRightPath([
            bidderPlatform,
            catalogue,
            lotListing,
            wishlist,
          ]);
        default:
          return false;
      }
    }
    return false;
  };

  useEffect(() => {
    if (!isEmpty(appAccessInfo) && id) {
      const shouldUpdate = accessUpdateHelper();
      if (shouldUpdate) {
        setIsAccessUpdated(true);
        previousInfo.current = appAccessInfo;
      }
    }
  }, [appAccessInfo, id]);

  const unsetAccesChanged = () => {
    setIsAccessUpdated(false);
  };

  return [isAccessUpdated, unsetAccesChanged];
};

export default useAppAccess;
