import { CustomTooltip } from "./styled";

const Tooltip = ({ title = "", children, ...props }) => {
  return (
    <CustomTooltip title={title} className="cursor-pointer" {...props}>
      {children}
    </CustomTooltip>
  );
};
export default Tooltip;
