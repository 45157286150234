import styled from "@emotion/styled";
import { TextField } from "@mui/material";

import { ImageIcons } from "components";

export const TokenBox = styled.div(({ isSelected, colors }) => ({
  background: `${isSelected ? colors.fadedGray : colors.lightGray} !important`,
  border: `1px solid ${isSelected ? colors.primary : colors.gray2}`,
  borderRadius: "4px",
  padding: 12,
  fontWeight: 600,
  textAlign: "center",
  display: "flex",
  alignItems: "center",
  color: `${isSelected ? colors.black : colors.gray}`,
  position: "relative",
  cursor: "pointer",
}));

export const TokenSlider = styled.div(() => ({
  display: "flex",
  gap: 8,
  overflow: "auto",
  width: "22rem",
  padding: "16px",
  flexGrow: 1,
  position: "relative",
}));

export const TickImage = styled(ImageIcons)`
  position: absolute;
  height: 2rem;
  width: 2rem;
  bottom: 36px;
  left: 35px;
`;

export const SearchBox = styled.div(() => ({
  background: "white",
  padding: "16px",
}));

export const CustomTextField = styled(TextField)(({ colors }) => ({
  background: colors.lightGray,
  width: "9rem",
  "& :focus": {
    boxShadow: "none !important",
  },
}));
