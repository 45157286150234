import { lazy, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import { useTheme } from "@mui/styles";
import AuctionTimerCalculator from "listener/AuctionTimerCalculator";
import { translate } from "locale/formatter";
import {
  getCurrentLot,
  getCurrentLotMaxBidding,
  getLotById,
  getRemainingTime,
} from "slices/auction.query";
import { actions } from "slices/auction.slice";
import { currency } from "utils/currencyFormatter";

import { ImageIcons } from "components";
import LocalTimer from "components/LocalTimer";
import NetworkBar from "components/NetworkBar";
import Text from "components/Text";
import Timer from "components/Timer";

import Footer from "./Footer";
import { LotInfoWrapper } from "./styled";

const DangerCircle = lazy(() => import("components/dangerCircle"));
const getCurrentBid = (maxBid, lotInAuction, isLotSold) => {
  const { price, bidder_name, short_code } = maxBid || {};
  if (price && bidder_name) {
    return (
      <Box className="flex flex-column align-items-center">
        <Text>{translate(isLotSold ? "final_bid" : "current_bid")}</Text>
        <Text>
          {" "}
          <span style={{ fontSize: "64px", color: "#ff6164" }}>
            {currency(price)}
          </span>
        </Text>
        <Text textType="secondary" className="!text-4xl">
          <b>{short_code}</b>
        </Text>
        <Text textType="secondary" className="!text-2xl capitalize">
          ({bidder_name})
        </Text>
      </Box>
    );
  }

  return (
    <Box className="flex flex-column align-items-center">
      <ImageIcons
        name="CircularSuccess"
        alt="circular-success"
        className="h-20 w-20 mb-1"
      />
      <Text type="secondary">{translate("waiting_for_the_first_bidder")}</Text>
    </Box>
  );
};

const AuctioneerTimerPage = () => {
  const { id: auctionId, lotId } = useParams();

  const dispatch = useDispatch();
  useEffect(() => {
    return dispatch(actions.loadCurrentAuction(auctionId));
  }, []);

  const aucManager = useSelector((state) => state.auction);

  const currentLot = getCurrentLot(aucManager);
  const auctionedLot = getLotById(aucManager, lotId);

  const maxBid = getCurrentLotMaxBidding(aucManager);

  const remainingTime = getRemainingTime(currentLot, maxBid);

  const lotInAuction = currentLot || auctionedLot;

  const { state = {} } = useLocation();
  const { colors } = useTheme();

  const startingBid = state?.startingBid || null;
  const nextLot = JSON.parse(state?.nextLot) || null;

  const [basePrice, setBasePrice] = useState(startingBid);

  useEffect(() => {
    if (lotInAuction?.base_price !== basePrice)
      setBasePrice(lotInAuction?.base_price);
  }, [lotInAuction]);

  const navigate = useNavigate();
  const [winningBid, setWinningBid] = useState({});

  const isLotSold = winningBid?.price && +remainingTime === 0;

  useEffect(() => {
    if (maxBid) {
      setWinningBid(maxBid);
    }
  }, [maxBid]);

  return (
    <Box
      style={{ background: colors.lightGray }}
      className=" w-100 flex flex-column h-100"
    >
      <Box className="flex align-items-center pt-4 my-6 mx-4 justify-between align-items-center">
        {!remainingTime ? (
          <Box className="h-6 w-6">
            <ImageIcons
              name="backBtn"
              alt="back"
              onClick={() => navigate(-1)}
              className="cursor-pointer"
            />
          </Box>
        ) : (
          <div />
        )}
        <Box className="flex align-items-center gap-3" data-cy="auction_status">
          <div className="flex align-items-center">
            <DangerCircle />
            <Text color={colors.red} className="ml-1">
              {translate("now_in_auction")}
            </Text>
          </div>
          <NetworkBar />
        </Box>
      </Box>
      <LotInfoWrapper className="mx-4 !rounded-xl" colors={colors}>
        <Grid container className="px-4 py-4 align-items-center">
          <Grid item xs={6} className="flex align-items-center">
            <ImageIcons
              name="PomegranateSmall"
              alt="pomegranate"
              style={{ width: "30px", height: "30px" }}
            />
            <Box className="flex flex-column ml-3">
              <Text>{auctionedLot?.grade}</Text>
              <Text textType="secondary">{translate("pomegranate")}</Text>
            </Box>
          </Grid>
          <Grid item xs={6} className="text-right">
            <Text style={{ color: colors.black }}>
              {auctionedLot?.weight} {translate("Kgs")} / {auctionedLot?.units}{" "}
              {translate("units")}
            </Text>
          </Grid>
        </Grid>
        <Box
          className="flex py-2 px-4"
          style={{
            background: colors.primary,
            color: colors.white,
            borderRadius: "0px 0px 0.75rem 0.75rem",
            fontWeight: 700,
          }}
        >
          <Grid container>
            <Grid item xs={8}>
              {translate("bid_opened_at")}
            </Grid>
            <Grid item xs={4} className="text-right">
              {currency(lotInAuction?.base_price || startingBid)}
            </Grid>
          </Grid>
        </Box>
      </LotInfoWrapper>
      <Box className="flex flex-column flex-grow-1 relative bg-white">
        <Box
          className="flex flex-col gap-1 mx-4 align-items-center justify-center"
          style={{ background: colors.white, flexGrow: 2 }}
        >
          {isLotSold && <ImageIcons name="SoldWaterMark" width="140px" />}
          {getCurrentBid(winningBid, lotInAuction, isLotSold)}
        </Box>
        <Box
          className="pt-4 px-4 pb-8"
          style={{
            background: colors.primary,
            width: "100%",
            minHeight: "10rem",
          }}
        >
          <Box className="flex justify-center" data-cy="auction.timer">
            {+remainingTime > 0 && (
              <LocalTimer seconds={remainingTime} timeOnLoad={Date.now()}>
                <Timer />
              </LocalTimer>
            )}
          </Box>
          <Footer
            test="maxBid"
            remainingTime={remainingTime}
            maxBid={maxBid}
            basePrice={basePrice}
            setBasePrice={setBasePrice}
            auctionedLot={lotInAuction}
            isSold={winningBid?.price}
            nextLot={nextLot}
          />
        </Box>
      </Box>
      {!!currentLot && (
        <AuctionTimerCalculator
          lot={{
            ...currentLot,
            maxBid,
            computedRemainingTime: remainingTime,
          }}
        />
      )}
    </Box>
  );
};

export default AuctioneerTimerPage;
