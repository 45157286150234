import { Box, Grid } from "@mui/material";
import { translate } from "locale/formatter";
import { colors, fonts } from "styles/AtomicStyles";
import { formatDecimalNumber } from "utils";
import { currency } from "utils/currencyFormatter";

import { ImageIcons } from "components";
import Text from "components/Text";

import { classes } from "./styled";

const OutstandingCard = ({ customerDetails, outstanding }) => {
  const {
    outstandingContainer,
    fontSize11,
    detailsClass,
    flexClass,
    outstandingText,
    logoContainer,
    outstandingTextColor,
    fontSize28,
    detailsFruitX,
  } = classes();

  const { totalOutstanding } = (customerDetails || []).reduce(
    (totals, acc) => {
      totals.totalOutstanding += +acc.outstanding_amount || 0;
      return totals;
    },
    { totalOutstanding: 0 }
  );

  return (
    <Box className={outstandingContainer}>
      <Grid container>
        <Grid container className={flexClass}>
          <Grid item md={12} xs={12} className={outstandingText}>
            <Text
              className={`${fontSize11} ${outstandingTextColor}`}
              style={{ fontFamily: fonts.NOTO_SANS }}
            >
              {translate("total_outstanding")}
            </Text>
          </Grid>
        </Grid>
        <Grid container className={detailsFruitX}>
          <Grid item md={6} xs={6} className={detailsClass}>
            <Text className={fontSize28}>
              <span className="!text-2xl relative -top-4">
                {translate("INR")}
              </span>
              <span className="!text-5xl !font-bold">
                {currency(
                  formatDecimalNumber(outstanding || totalOutstanding || 0)
                    .integer,
                  false,
                  false,
                  false
                )}
                <Text size="large" color={colors.red}>
                  {
                    formatDecimalNumber(outstanding || totalOutstanding || 0)
                      .decimal
                  }
                </Text>
              </span>
            </Text>
          </Grid>
          <Grid item md={6} xs={6} className={logoContainer}>
            <ImageIcons name="Logo" alt="fruitX-logo" />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default OutstandingCard;
