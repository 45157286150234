import firebase from "firebase/app";

import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/performance";
import "firebase/analytics";

import config from "../config";

firebase.initializeApp(config.firebase);
firebase.analytics();

export const auth = firebase.auth();
export const storage = firebase.storage().ref();
export const performance = firebase.performance();
export const firestore = firebase.firestore();
export const { FieldValue } = firebase.firestore;
export const { logEvent } = firebase.analytics();

if (window.Cypress) {
  // https://github.com/cypress-io/cypress/issues/2374
  firestore.settings({ experimentalForceLongPolling: true });
  auth.useEmulator("http://127.0.0.1:9099");
  firestore.useEmulator("127.0.0.1", 8080);
}

// if (process.env.NODE_ENV === "development") {
//   auth.useEmulator("http://127.0.0.1:9099");
//   firestore.useEmulator("127.0.0.1", 8080);
// }

export default firebase;
