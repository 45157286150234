import { colors } from "styles/AtomicStyles";

import { TEXT_TYPES } from "./const";

export const getComponent = (textType) => {
  const component = {
    [TEXT_TYPES.PRIMARY]: "b",
    [TEXT_TYPES.SECONDARY]: "span",
  };
  return component[textType];
};

export const getStyle = (textType = TEXT_TYPES.PRIMARY) => {
  const style = {
    [TEXT_TYPES.PRIMARY]: {
      fontSize: "14px",
      color: colors.black,
      fontWeight: 700,
    },
    [TEXT_TYPES.SECONDARY]: {
      fontSize: "12px",
      fontWeight: 500,
      color: colors.gray,
    },
  };
  return style[textType];
};
