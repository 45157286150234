let userObj = null;
let userPermission = null;
let userDcId = null;

export const saveUserData = (user) => {
  userObj = user;
  localStorage.setItem("user", JSON.stringify(user));
};

export const removeUser = () => {
  userObj = null;
  userPermission = null;
  localStorage.removeItem("user");
  localStorage.removeItem("userpermission");
  localStorage.removeItem("dcid");
  localStorage.removeItem("policyAccepted");
};

export const getUserData = () => {
  userObj = localStorage.user ? JSON.parse(localStorage.user) : null;

  return userObj;
};

export const getMemoizedUserData = () => userObj;

export const getSavedPermissions = () => {
  if (userPermission) {
    return userPermission;
  }

  userPermission = localStorage.userpermission
    ? JSON.parse(localStorage.userpermission)
    : null;

  return userPermission;
};

export const saveUserPermission = (permission) => {
  userPermission = permission;
  localStorage.setItem("userpermission", JSON.stringify(permission));
};

export const getSavedUserDCId = () => {
  if (userDcId) {
    return userDcId;
  }

  userDcId = localStorage.dcid || null;

  return userDcId;
};

export const saveUserDCId = (id) => {
  userDcId = id;
  localStorage.setItem("dcid", id);
};

export const setMandiId = (id) => {
  localStorage.setItem("mandiId", id);
};

export const getMandiId = () => {
  return +localStorage.getItem("mandiId");
};

export const setMandiList = (list) => {
  localStorage.setItem("mandiList", JSON.stringify(list));
};

export const getMandiList = () => {
  return JSON.parse(localStorage.getItem("mandiList"));
};

export const currentMandiDetails = (mandiId = getMandiId()) => {
  const mandiList = JSON.parse(localStorage.getItem("mandiList"));
  return mandiList?.find((mandi) => mandi.id === mandiId);
};

export const setCurrentPage = (name) => {
  localStorage.setItem("page", name);
};

export const getPreviousPage = () => {
  return localStorage.getItem("page");
};

export const savePrivacyPolicy = (key, value) => {
  localStorage.setItem(key, value);
};

export const getPrivacyPolicy = () => {
  const isPrivacyPolicyAccepted = localStorage.getItem("policyAccepted");

  return isPrivacyPolicyAccepted ? JSON.parse(isPrivacyPolicyAccepted) : false;
};
