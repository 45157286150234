import styled from "@emotion/styled";
import { Grid } from "@mui/material";
import { useTheme } from "@mui/styles";
import { useImageAndColor } from "hooks/useImageAndColor";
import { translate } from "locale/formatter";
import { isEmpty } from "lodash";
import { currency } from "utils/currencyFormatter";
import { formatDate } from "utils/dateUtils";

import BidderInfoCard from "components/BidderInfoCard";
import DottedDivider from "components/Divider/DottedDivider";
import Text from "components/Text";
import { TEXT_TYPES } from "components/Text/const";

import { PurchaseCardWrapper } from "../dashboard/styled";

const PurchaseCard = ({ data }) => {
  const { colors } = useTheme();
  const { imageTag, bgColor } = useImageAndColor(
    data?.mandi_product_categories
  );

  const MandiName = styled.div(({ colors }) => ({
    padding: "0.25rem",
    borderRadius: "2px",
    background: bgColor,
    boxShadow: `0px 1px 1px ${colors.silver}`,
  }));

  if (isEmpty(data)) return <></>;
  const { mandi_app_name, mandi_name, auction_date, total_value } = data;
  return (
    <PurchaseCardWrapper colors={colors}>
      <Grid container className="flex p-3">
        <Grid item xs={3}>
          {imageTag}
        </Grid>
        <Grid item xs={9} className="flex flex-column justify-content-end pl-2">
          <div className="flex justify-between">
            <MandiName colors={colors}>
              <Text size="10px">{mandi_app_name || mandi_name}</Text>
            </MandiName>
            <Text size="10px">{formatDate(auction_date)}</Text>
          </div>
          <Text textType={TEXT_TYPES.SECONDARY} size="14px">
            {translate("total_value")}
          </Text>
          <Text size="18px">{currency(total_value)}</Text>
        </Grid>
      </Grid>
      <DottedDivider />
      {/* Stats Info Card */}
      <BidderInfoCard data={data} />
    </PurchaseCardWrapper>
  );
};

export default PurchaseCard;
