import React from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import { Grid, IconButton, Typography } from "@mui/material";
import { translate } from "locale/formatter";
import { removeFruitX } from "utils";

import { classes, OnlineIndicator } from "../styled";

import { ProductImage, useStyles } from "./styled";

const Header = ({
  moveToNextPage,
  setMoveToNextPage,
  isMandiActive,
  mandiName,
  setOpenPayNow,
}) => {
  const { fontNoto } = classes();
  const {
    headerMargin,
    marginOnlineIndicator,
    backButton,
    alignCenter,
    fixHeader,
    marginRight,
  } = useStyles();
  return (
    <div className={fixHeader}>
      <div className={headerMargin}>
        <Grid container justifyContent="space-between">
          <Grid item spacing={2} className={alignCenter}>
            {moveToNextPage && (
              <ProductImage
                name="backBtn"
                alt="backArrow"
                onClick={() => setMoveToNextPage(false)}
                className={`${marginOnlineIndicator} ${marginRight}`}
              />
            )}
            <div>
              <Typography variant="h5">
                <b>{translate("pay_now")}</b>
              </Typography>
              <Grid display="flex">
                <OnlineIndicator
                  isOnline={isMandiActive}
                  className={marginOnlineIndicator}
                />
                <Typography variant="h6" className={fontNoto}>
                  {removeFruitX(mandiName)}
                </Typography>
              </Grid>
            </div>
          </Grid>
          <Grid item>
            <IconButton
              onClick={() => {
                setOpenPayNow(false);
                setMoveToNextPage(false);
              }}
              className="pr-0"
            >
              <CancelIcon fontSize="large" className={backButton} />
            </IconButton>
          </Grid>
        </Grid>
      </div>
      <hr />
    </div>
  );
};

export default Header;
