import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { translate } from "locale/formatter";

import Text from "components/Text";

const Loader = () => {
  return (
    <Box className="flex items-center justify-center w-full h-full flex flex-col gap-2">
      <CircularProgress />
      <Text>{translate("loading")}...</Text>
    </Box>
  );
};

export default Loader;
