import { Box } from "@mui/material";
import { useTheme } from "@mui/styles";
import { translate } from "locale/formatter";

import Text from "components/Text";

const AuctionInfoBanner = ({ auctionedTokens, totalTokens }) => {
  const { colors } = useTheme();
  return (
    <Box
      className="py-1 flex justify-center"
      style={{ background: colors.violet, width: "100vw" }}
    >
      <Text style={{ color: colors.white }}>
        {auctionedTokens} {translate("of")} {totalTokens}{" "}
        <span style={{ fontWeight: 500 }}>
          {translate("tokens")} {translate("auctioned")}
        </span>
      </Text>
    </Box>
  );
};

export default AuctionInfoBanner;
