import styled from "@emotion/styled";
import { makeStyles } from "@mui/styles";

export const AuctionStatusBanner = styled.div(({ colors }) => ({
  background: colors.primary,
  borderRadius: "0 0 5px 5px",
  color: colors.white,
  fontWeight: 600,
  padding: "0.5rem",
  margin: "1rem",
}));

export const styles = makeStyles(({ colors }) => ({
  currentBid: {
    color: `${colors.mustardYellow3} !important`,
    fontSize: "4.6rem !important",
    lineHeight: "6.3rem",
    fontWeight: "900 !important",
    textShadow: "0px 4px 2px rgba(0, 0, 0, 0.25)",
  },
  sup: {
    top: "2rem",
    fontSize: "1.7rem",
    right: "2.7rem",
  },
  text: {
    color: `${colors.white} !important`,
    fontSize: "1.6rem !important",
    marginRight: "1rem",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
  },
  currentBidContainer: {
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  soldTag: {
    transform: "rotate(-9.61deg)",
    height: " 5rem",
    width: "6rem",
    margin: "1rem 1rem 0 2rem",
  },
}));
