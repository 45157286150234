import React, { useState } from "react";
import { Button } from "@mui/base";
import { Box, Grid, Stack } from "@mui/material";
import useMoEngage from "hooks/useMoEngage";
import { colors } from "styles/AtomicStyles";
import { currency } from "utils/currencyFormatter";
import { getFormattedDateTime } from "utils/dateUtils";
import { MY_PAYMENTS_LISTING_PAGE_EVENTS, PAGE_EVENTS } from "utils/events";
import { roundToTwoDecimal } from "utils/mathExpert";

import { ImageIcons } from "components";
import Text from "components/Text";

import { PAYMENT_STATUS } from "./const";
import PaymentBreakup from "./PaymentBreakup";
import { classes } from "./styled";

const MyPayments = ({ data, getAllSplitPaymentsInfo, splitInfo }) => {
  const {
    amount_paid = "",
    transaction_time = "",
    payment_type = "",
    ref_number = "",
    record_type,
    record_id,
    payment_amount = 0,
  } = data || {};

  const style = classes();
  const tracker = useMoEngage();

  const [open, setOpen] = useState(false);
  const handelOpenPopup = () => {
    tracker(
      MY_PAYMENTS_LISTING_PAGE_EVENTS.MY_PAYMENTS_VALUE_HYPER_LINK_CLICK,
      {
        pageUrl: PAGE_EVENTS.MANDI_FILTERED,
      }
    );
    setOpen(true);
    getAllSplitPaymentsInfo(record_type, record_id);
  };

  const handleClosePopUp = () => {
    setOpen(false);
  };

  const getImageType = {
    [PAYMENT_STATUS.BANK]: "Bank",
    [PAYMENT_STATUS.UPI]: "Upi",
    [PAYMENT_STATUS.CASH]: "Cash",
  };

  const imageType = getImageType[payment_type];

  return (
    <Stack className="flex flex-column">
      <Box className={style.paymentBorder} onClick={() => handelOpenPopup()}>
        <Stack>
          <Grid container className="py-3">
            <Grid item xs={6}>
              <Grid container>
                <Grid item xs={2}>
                  <ImageIcons
                    name={imageType}
                    alt="Rupees"
                    style={{ height: "22px", width: "22px" }}
                  />
                </Grid>
                <Grid item xs={10}>
                  <Text>
                    <Text style={{ fontWeight: "400" }}>{payment_type}</Text>
                    <Text component="div" textType="secondary" size="10px">
                      {getFormattedDateTime(transaction_time)}
                    </Text>
                  </Text>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Stack className="flex justify-end">
                <div style={{ display: "flex", justifyContent: "end" }}>
                  <Text
                    component="div"
                    textType="secondary"
                    className={style.fontSize12}
                    style={{ color: colors.primary, marginTop: ".04rem" }}
                  >
                    ₹
                  </Text>
                  &nbsp;
                  <Button
                    type="button"
                    className="text-2xl"
                    style={{ color: colors.primary }}
                  >
                    <b>
                      {currency(
                        roundToTwoDecimal(amount_paid || payment_amount),
                        false,
                        false,
                        false
                      )}
                    </b>
                  </Button>
                </div>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </Box>
      {open && (
        <PaymentBreakup
          open={open}
          handleClosePopUp={handleClosePopUp}
          splitInfo={splitInfo}
          refNumber={ref_number}
          transactionTime={transaction_time}
          paymentType={payment_type}
        />
      )}
    </Stack>
  );
};

export default MyPayments;
