import React from "react";
import { createIntl, createIntlCache, RawIntlProvider } from "react-intl";

import messages from "../languages/en.json";

const cache = createIntlCache();

const intl = createIntl({ messages, locale: "en-IN" }, cache);
export const formatMessage = (id, values) => intl.formatMessage({ id }, values);

const LocaleProvider = (props) => {
  return <RawIntlProvider value={intl}>{props.children}</RawIntlProvider>;
};

export default LocaleProvider;
