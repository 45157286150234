import { Notifier } from "@airbrake/browser";

import { getUserData } from "./localStorage";

let airbrakeObj = null;

export const initializeService = () => {
  if (process.env.NODE_ENV === "production") {
    airbrakeObj = new Notifier({
      projectId: 477236,
      projectKey: "1f58d84ba9b5a5ee7937b7c7ddd52a5a",
      environment: process.env.NODE_ENV,
    });
  }
};

export const logError = (error, errorInfo) => {
  if (!airbrakeObj) {
    console.log("no error obj initialize");
    return false;
  }

  if (process.env.NODE_ENV === "production") {
    airbrakeObj.notify({
      error,
      params: { info: errorInfo },
      session: { username: getUserData()?.username || "" },
    });
  }

  return true;
};
