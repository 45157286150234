import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { translate } from "locale/formatter";

import Pills from "components/Pills";
import { PILL_TYPES } from "components/Pills/const";
import Text from "components/Text";

const ConfirmationDialog = ({
  header = "",
  open = false,
  content = "",
  primaryBtntext = translate("yes"),
  secondaryBtnText = translate("no"),
  onCloseHandler = () => {},
  secondaryBtnHandler = () => {},
  primaryBtnHandler = () => {},
  isPrimaryButtonDisabled = false,
}) => {
  const { colors } = useTheme();
  const primaryButtonType = isPrimaryButtonDisabled ? PILL_TYPES.DISABLED : "";
  return (
    <Dialog open={open} onClose={onCloseHandler} fullWidth>
      <DialogTitle>{header}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions className="flex gap-3">
        <Text
          onClick={secondaryBtnHandler}
          className="underline mr-4"
          color={colors.primary}
        >
          {secondaryBtnText}
        </Text>
        {primaryBtntext && (
          <Pills
            handleOnClick={primaryBtnHandler}
            disabled={isPrimaryButtonDisabled}
            type={primaryButtonType}
          >
            {primaryBtntext}
          </Pills>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
