import styled from "@emotion/styled";
import { makeStyles } from "@mui/styles";
import { fonts } from "styles/AtomicStyles";

import { ImageIcons } from "components";

export const ProductImage = styled(ImageIcons)`
  height: 66px;
  width: 66px;
`;

export const PreviousBuys = styled.div(({ islastcard }) => ({
  marginRight: islastcard === "true" ? 0 : ".8rem",
  marginBottom: "2rem",
  minWidth: "90%",
  maxWidth: "100%",
}));

export const PurchaseCardWrapper = styled.div(({ colors }) => ({
  background: colors.lightGray,
  borderRadius: "12px",
  border: "2px solid #eeeeee",
}));

export const styles = makeStyles(({ colors }) => ({
  mandiName: {
    background: `${colors.mustardYellow} !important`,
    padding: "0.25rem 0.5rem",
    borderRadius: "0.375rem",
  },
  dashboardWrapper: {
    padding: "0 1rem",
    marginTop: "7.5rem",
    backgroundColor: colors.smokeWhite,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    font: fonts.NOTO_SANS,
  },
  layoutWrapper: {
    fontFamily: fonts.NOTO_SANS,
    position: "absolute",
    top: "10rem",
    marginLeft: ".3rem",
    fontSize: "14px",
  },
}));
