import { Typography } from "@mui/material";
import { useTheme } from "@mui/styles";

import { TEXT_TYPES } from "./const";
import { getComponent, getStyle } from "./utils";

const Text = ({
  textType = TEXT_TYPES.PRIMARY,
  children,
  style,
  component,
  align = "inherit",
  variant = "body1",
  color = "",
  sx = {},
  size,
  className,
  ...props
}) => {
  const { colors } = useTheme();

  const textColor =
    color || (textType === TEXT_TYPES.PRIMARY ? colors.black : colors.gray);

  const fontSize = size || (textType === TEXT_TYPES.PRIMARY ? "14px" : "12px");

  return (
    <Typography
      component={component ?? getComponent(textType)}
      style={{ ...getStyle(textType), color: textColor, fontSize, ...style }}
      align={align}
      variant={variant}
      sx={sx}
      className={`normal-case ${className || ""}`}
      {...props}
    >
      {children}
    </Typography>
  );
};

export default Text;
