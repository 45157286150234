import React from "react";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import {
  Box,
  FormControlLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import useTracker from "hooks/useTracker";
import { formatMessage } from "locale/components/locale-provider";
import { translate } from "locale/formatter";
import { currency } from "utils/currencyFormatter";
import { PAYMENT_MODE, PAYMENT_OPTION } from "utils/payNow";

import { ImageIcons } from "components";
import Input from "components/Input";

import { useStyles } from "./styled";

const PaymentInfo = ({
  paymentOption,
  setPaymentOption,
  outStandingAmount,
  overdueAmount,
  enterAmountVal,
  setEnterAmountVal,
  setPaymentMode = () => {},
}) => {
  const SET_UPI_LIMIT = 100000;

  const inputStyles = {
    padding: "0.7rem",
    fontSize: "1.5rem",
    background: "#fff",
    fontWeight: "bold",
  };

  const {
    selectedRadio,
    verticalAlign,
    alignEnd,
    boxContainer,
    selectedBox,
    alignCenter,
    imageIcon,
    arrowIconStyle,
    textInputStyle,
  } = useStyles();

  const trackUser = useTracker();

  const { BANK, UPI } = PAYMENT_MODE;
  const { ENTER_AMOUNT, OUTSTANDING, OVERDUE } = PAYMENT_OPTION;

  const handleOptionChange = (event) => {
    setPaymentOption(event.target.value);
  };

  const determinePaymentMode = (amount) => {
    const currentMethod = amount >= SET_UPI_LIMIT ? BANK : UPI;

    return currentMethod;
  };

  const modeOfPayment = (mode, amount) => {
    if (paymentOption !== mode) {
      return null;
    }

    const currentPaymentMode = determinePaymentMode(amount);
    setPaymentMode(currentPaymentMode);

    if (mode === ENTER_AMOUNT && !enterAmountVal) {
      return (
        <div className={alignCenter}>
          <Typography variant="h6" color="textSecondary" align="right">
            {translate("mode_of_payment")}
          </Typography>
        </div>
      );
    }

    return (
      <div className={alignCenter}>
        <Typography variant="h6" color="textSecondary" align="right">
          {translate("mode_of_payment")}
        </Typography>
        <ImageIcons
          name={currentPaymentMode === UPI ? "IMG_UPI" : "IMG_BANK_ICON"}
          className={imageIcon}
        />
      </div>
    );
  };

  const handlePaymentOptionClick = (option) => {
    setPaymentOption(option);
    trackUser("paymentOptionClicked", {
      PaymentOption: option,
    });
  };

  const displayLabel = (label) => {
    return (
      <Box display="flex" justifyContent="space-between" width="100%">
        <Typography variant="h5">{formatMessage(label)}</Typography>
      </Box>
    );
  };

  const boxClass = (paymentMode) => {
    return `${boxContainer} ${paymentOption === paymentMode && selectedBox}`;
  };

  return (
    <RadioGroup
      aria-label="payment-option"
      name="payment-option"
      value={paymentOption}
      onChange={handleOptionChange}
    >
      <Box
        className={boxClass(OUTSTANDING)}
        onClick={() => handlePaymentOptionClick(OUTSTANDING)}
      >
        <FormControlLabel
          value={OUTSTANDING}
          control={
            <Radio
              color="primary"
              className={paymentOption === OUTSTANDING && selectedRadio}
            />
          }
          label={displayLabel("pay_outstanding")}
        />
        <div className={verticalAlign}>
          <div>
            <div className={alignEnd}>
              <Typography variant="h5" textAlign="end">
                <b>{currency(outStandingAmount)}</b>
              </Typography>
              <ArrowRightAltIcon className={arrowIconStyle} fontSize="large" />
            </div>
            {modeOfPayment(OUTSTANDING, outStandingAmount)}
          </div>
        </div>
      </Box>
      <Box
        className={boxClass(OVERDUE)}
        onClick={() => handlePaymentOptionClick(OVERDUE)}
      >
        <FormControlLabel
          value={OVERDUE}
          control={
            <Radio
              color="primary"
              className={paymentOption === OVERDUE && selectedRadio}
            />
          }
          label={displayLabel("pay_overdue")}
        />
        <div className={verticalAlign}>
          <div>
            <div className={alignEnd}>
              <Typography variant="h5" textAlign="end">
                <b>{currency(overdueAmount)}</b>
              </Typography>
              <ArrowRightAltIcon className={arrowIconStyle} fontSize="large" />
            </div>
            {modeOfPayment(OVERDUE, overdueAmount)}
          </div>
        </div>
      </Box>
      <Box
        className={boxClass(ENTER_AMOUNT)}
        onClick={() => handlePaymentOptionClick(ENTER_AMOUNT)}
      >
        <FormControlLabel
          value={ENTER_AMOUNT}
          control={
            <Radio
              color="primary"
              className={paymentOption === ENTER_AMOUNT && selectedRadio}
            />
          }
          label={displayLabel("enter_Amount")}
        />
        <div className={verticalAlign}>
          <div>
            <div className={alignEnd}>
              {paymentOption === ENTER_AMOUNT ? (
                <div>
                  <div className={alignEnd}>
                    <Input
                      name="enterAmtVal"
                      type="number"
                      startAdornment={
                        <InputAdornment position="start">₹</InputAdornment>
                      }
                      inputStyles={inputStyles}
                      className={textInputStyle}
                      onChange={(e) => setEnterAmountVal(e.target.value)}
                      value={enterAmountVal}
                    />
                  </div>
                  {modeOfPayment(ENTER_AMOUNT, enterAmountVal)}
                </div>
              ) : (
                <ArrowRightAltIcon
                  className={arrowIconStyle}
                  fontSize="large"
                />
              )}
            </div>
          </div>
        </div>
      </Box>
      <div />
    </RadioGroup>
  );
};

export default PaymentInfo;
