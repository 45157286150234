import styled from "@emotion/styled";

export const Textarea = styled.textarea(() => ({
  width: "100% !important",
  resize: "none",
  border: "1px solid #DFDFDF",
  borderRadius: "0.75rem",
  padding: "0.5rem",
  height: "10rem",
}));
