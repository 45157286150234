import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { translate } from "locale/formatter";
import { path, settings } from "utils/const";

import AlertDialog from "components/Dialog/AlertDialog";
import Text from "components/Text";
import { TEXT_TYPES } from "components/Text/const";

import { classes, dialogContentStyle, dialogStyle } from "./styled";

const HomePageRedirection = ({ onClose = () => {} }) => {
  const navigate = useNavigate();
  const { bodyWrapper, timerClass, subHeader, commonClass } = classes();
  const [timeLeft, setTimeLeft] = useState(settings.bidderAccessRedirection);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft((t) => {
        if (t <= 0) {
          clearInterval(interval);
          return;
        }
        return t - 1;
      });
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (timeLeft <= 0) {
      navigate(path.bidderDashboard);
      onClose();
    }
  }, [timeLeft, history]);

  const body = (
    <div className={bodyWrapper}>
      <Text className={commonClass}>
        {translate("your_access_has_been_updated")}
      </Text>
      <Text textType={TEXT_TYPES.SECONDARY} className={subHeader}>
        {translate("you_are_being_redirected_to_homepage_in")}
      </Text>
      <Text className={commonClass}>
        <span className={timerClass}>{timeLeft}</span>S
      </Text>
    </div>
  );

  return (
    <AlertDialog
      open={timeLeft > 0}
      content={body}
      showActions={false}
      dialogContentStyle={dialogContentStyle}
      dialogStyle={dialogStyle}
    />
  );
};

export default HomePageRedirection;
