import { colors } from "../../styles/AtomicStyles";

import { PILL_TYPES } from "./const";

export const getStyles = (type, style) => {
  let buttonStyle = { borderRadius: 20, padding: 8, ...style };
  switch (type) {
    case PILL_TYPES.OUTLINED:
      buttonStyle = {
        ...buttonStyle,
        borderColor: colors.primary,
        color: colors.primary,
      };
      break;
    case PILL_TYPES.CONTAINED_SECONDARY:
      buttonStyle = {
        ...buttonStyle,
        background: colors.lightGreen,
        color: colors.white,
        borderColor: colors.lightGreen,
      };
      break;
    case PILL_TYPES.CONTAINED_WHITE:
      buttonStyle = {
        ...buttonStyle,
        background: colors.white,
        color: colors.primary,
        borderColor: colors.white,
      };
      break;
    case PILL_TYPES.DISABLED:
      buttonStyle = {
        ...buttonStyle,
        background: colors.disabled,
        color: colors.black,
        borderColor: colors.white,
      };
      break;
    default:
      buttonStyle = {
        ...buttonStyle,
        background: colors.primary,
        color: colors.white,
      };
  }
  return buttonStyle;
};

export const getVariant = (type) =>
  type === PILL_TYPES.OUTLINED ? "outlined" : "contained";
