/* eslint-disable import/imports-first */
import { forwardRef, useEffect, useState } from "react";
import { useTheme } from "@mui/styles";
import { translate } from "locale/formatter";

import Text from "components/Text";

import { CtaSection, CustomDatepicker } from "./styled";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

const initialState = {
  startDate: new Date(),
  endDate: null,
  key: "selection",
};

// Make changes cautiously since it's a package based component
const DateRangePicker = forwardRef(
  ({ onChange, range, initialiseDatepicker, trackEvents = () => {} }, ref) => {
    const [state, setState] = useState([initialState]);

    const { colors } = useTheme();
    // To retain the selected dates
    useEffect(() => {
      if (range) {
        setState([range]);
      }
    }, [range]);

    useEffect(() => {
      if (initialiseDatepicker) {
        setState([initialState]);
        onChange([initialState]);
      }
    }, [initialiseDatepicker]);

    return (
      <div className="w-max position-relative">
        <CustomDatepicker
          ref={ref}
          ranges={state}
          editableDateInputs
          maxDate={new Date()}
          showDateDisplay={false}
          showMonthAndYearPickers={false}
          onChange={(item) => setState([item.selection])}
          colors={colors}
          className="date-picker"
        />
        <CtaSection colors={colors}>
          <div />
          <Text
            className="ctaBtn"
            onClick={() => {
              onChange(state);
              trackEvents();
            }}
          >
            {translate("done")}
          </Text>
        </CtaSection>
      </div>
    );
  }
);

export default DateRangePicker;
