import styled from "@emotion/styled";
import { makeStyles } from "@mui/styles";

export const style = makeStyles(() => ({
  otpGroup: {
    display: "flex",
    width: "100%",
    columnGap: "0.5rem",
  },
  otpInput: {},
}));

export const CustomInput = styled.input(({ colors, textColor }) => ({
  width: "100%",
  height: "4.5rem",
  border: "none",
  borderRadius: "0.5rem",
  textAlign: "center",
  fontSize: "2rem",
  fontWeight: "bold",
  color: `${textColor} !important`,
  background: colors.offGray,
}));
