import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { translate } from "locale/formatter";
import { actions as aucActions } from "slices/auction.slice";

import ConfirmationDialog from "components/Dialog/ConfirmationDialog";

const ReturnToFarmerPopup = ({
  showPopup = false,
  closePopup = () => {},
  handleReturnToFarmer = () => {},
}) => {
  const dispatch = useDispatch();
  const noButtonHandler = () => {
    closePopup();
  };
  const yesButtonHandler = () => {
    handleReturnToFarmer();
    closePopup();
  };

  useEffect(() => {
    return () => {
      dispatch(aucActions.isLotAvailableForBid(false));
    };
  }, []);

  return (
    <ConfirmationDialog
      open={showPopup}
      onCloseHandler={closePopup}
      header={translate("confirmation")}
      content={translate("do_you_want_to_return_this_lot_to_farmer?")}
      primaryBtnHandler={yesButtonHandler}
      secondaryBtnHandler={noButtonHandler}
    />
  );
};

export default ReturnToFarmerPopup;
