import { useEffect, useState } from "react";
import { InputAdornment } from "@mui/material";
import { useTheme } from "@mui/styles";
import { translate } from "locale/formatter";
import { COUNTRY_CODE } from "utils/const";

import { ImageIcons } from "components";
import Text from "components/Text";

import { classes, PhoneInputField } from "./styled";

const PhoneInput = ({
  showLabel = true,
  setUserContactNumber = () => {},
  userContactNumber,
  phoneNumberError = "",
  setPhoneNumberError = () => {},
}) => {
  const { input, tickImage, label } = classes();
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(
    `${userContactNumber}`.length === 10
  );
  const [contact, setContact] = useState(userContactNumber || "");

  const { colors } = useTheme();

  useEffect(() => {
    const contactLength = `${contact}`.length;
    if (contactLength === 10) {
      setIsValidPhoneNumber(true);
    } else if (isValidPhoneNumber) {
      if (phoneNumberError) {
        setPhoneNumberError("");
      }
      setIsValidPhoneNumber(false);
    } else if (contactLength < 10 && phoneNumberError) {
      setPhoneNumberError("");
    }
  }, [contact]);

  useEffect(() => {
    if (isValidPhoneNumber) {
      setUserContactNumber(contact);
    } else if (!isValidPhoneNumber && userContactNumber) {
      setUserContactNumber(undefined);
    }
  }, [isValidPhoneNumber]);

  useEffect(() => {
    if (phoneNumberError) setIsValidPhoneNumber(false);
  }, [phoneNumberError]);

  return (
    <div className="w-100">
      {showLabel && (
        <Text component="div" className={label}>
          {translate("phone_number")}
        </Text>
      )}
      <PhoneInputField
        type="number"
        label=""
        onChange={({ target }) =>
          target.value.length <= 10 && setContact(target.value)
        }
        value={contact}
        colors={colors}
        InputProps={{
          id: "phone_input",
          startAdornment: (
            <InputAdornment position="start" sx={{ fontSize: 30 }}>
              <Text className="!bold !text-3xl">{COUNTRY_CODE.INDIA}</Text>
              <span className="ml-2">|</span>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {isValidPhoneNumber && (
                <ImageIcons
                  name="CheckCircle"
                  alt="check-circle"
                  className={tickImage}
                />
              )}
            </InputAdornment>
          ),
          classes: {
            input,
          },
          sx: {
            "& input": {
              color: phoneNumberError && colors.red,
            },
          },
        }}
      />
    </div>
  );
};

export default PhoneInput;
