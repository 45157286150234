import { savePing } from "../repo/firebaseRepo";
import { getCurrTime } from "../utils/date";

export const Ping = class {
  initiatedAt = null; // start-time

  respondedAt = null; // end-time

  constructor(initiatedAt) {
    this.initiatedAt = initiatedAt;
  }

  pingEndpoint = (userId, sessionId, pingId) => {
    savePing(userId, sessionId, pingId, this.initiatedAt);
  };

  ackResponse = (respondedAt) => {
    this.respondedAt = respondedAt;
  };

  hasAcknowledged = () => {
    return this.respondedAt !== null;
  };

  getLatency = () => {
    const respTime = this.respondedAt || getCurrTime(); // if no response, use current time
    return respTime - this.initiatedAt;
  };
};
