import { generatePath } from "react-router-dom";
import { path } from "utils/const";

const Mapping = {
  auction_ended: path.mybuys,
  favourite_lot_added: path.catalogue,
};

export const EventPathMapping = (event, data) => {
  return `${window.location.origin}${generatePath(Mapping[event], data)}`;
};
