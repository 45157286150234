import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { translate } from "locale/formatter";

import Pills from "components/Pills";

const AlertDialog = ({
  header = "",
  open = false,
  content = "",
  primaryBtntext = translate("ok"),
  handleClose = () => {},
  primaryBtnHandler = () => {},
  showActions = true,
  dialogContentStyle = {},
  dialogStyle = {},
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: dialogStyle,
      }}
    >
      {!!header && <DialogTitle>{header}</DialogTitle>}
      {!!content && (
        <DialogContent style={{ padding: "20px" }} sx={dialogContentStyle}>
          {content}
        </DialogContent>
      )}
      {showActions && (
        <DialogActions>
          <Pills handleOnClick={primaryBtnHandler}>{primaryBtntext}</Pills>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default AlertDialog;
