// function that converts time in secs to hh : mm : ss

export const formatTime = (totalSeconds) => {
  let hours = 0;
  let minutes = 0;
  let seconds = 0;
  let milliseconds = 0;
  let remainingSeconds = 0;
  if (totalSeconds > 0) {
    hours = Math.floor(totalSeconds / 3600);
    remainingSeconds = totalSeconds - hours * 3600;

    minutes = Math.floor(remainingSeconds / 60);
    remainingSeconds = totalSeconds - hours * 3600 - minutes * 60;

    seconds = Math.floor(remainingSeconds);
    const msFraction = remainingSeconds - seconds;

    milliseconds = Math.floor(60 * msFraction);
  }
  return { hh: hours, mm: minutes, ss: seconds, ms: milliseconds };
};
