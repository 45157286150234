import { Children, useCallback, useRef } from "react";

const InfiniteScroll = ({
  children,
  loading,
  hasMoreData = true,
  setPageNumber,
  className,
}) => {
  const observer = useRef();
  const lastElemRef = useCallback(
    (elem) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMoreData) {
          setPageNumber((previousPageNo) => previousPageNo + 1);
        }
      });
      if (elem) observer.current.observe(elem);
    },
    [loading, hasMoreData]
  );

  const childArray = Children.toArray(children);

  const childRenderer = Children.map(childArray, (child, index) => {
    const isLastElem = index === childArray.length - 1;
    if (isLastElem) {
      return (
        <div ref={lastElemRef} className={className}>
          {child}
        </div>
      );
    }
    return <div className={className}>{child}</div>;
  });

  return (
    <>
      {childRenderer}
      {loading && <div>...Loading</div>}
    </>
  );
};

export default InfiniteScroll;
