export const PrivacyPolicyConst = {
  accept_and_continue: "accept & continue",
  defined_below: "defined below",
  as_applicable: "as applicable",
  name: "Name",
  email: "Email:",
  posts: "Posts",
  services: "Services",
  platform: "Platform",
  changes_to_our_privacy_policy: "Changes To Our Privacy Policy:",
  collection_of_info_desc_1:
    "We collect Personal Information ({x13}) from You when You register or set up an account with Us on the Platform and while You avail the Services offered by the Platform.",
  collection_of_info_point_1:
    "Information You give Us: You may provide certain information to Us voluntarily while registering on Our Platform for availing the Services, such as your name, address, phone number, email address, photo. In order to verify Your identity, we may also collect an identification proof from You, which may include Your Aadhaar Card details (including a scanned copy or a photo copy of the Aadhaar Card),voter card and PAN card. We shall use Your Aadhaar Card details only for the purpose of verification of Your identity, in accordance with the Applicable Laws.",
  collection_of_info_point_10:
    "We also use tokens or cookies to allow You to enter Your password less frequently during a session on the Platform.",
  collection_of_info_point_11:
    "You might not be able to browse the Platform or use Our Services without providing Us Your required Personal Information. Once You give us your Personal Information, You are not anonymous to Us. Wherever possible, while providing the information to Us, We indicate which fields are mandatory and which fields are optional for You. You always have the option to not provide the Personal Information to Us through the Platform by choosing to not use a particular Service or feature being provided by Us on the Platform, which requires You to provide such information. We may automatically track certain information about You based upon Your behaviour on the Platform. We use this information to do internal research on Your demographics, interests, and behaviour to better understand, protect and serve You. This information is compiled and analysed by the Company on an aggregated basis and not individually on a personal level, in a manner that does not specifically identify You.",
  collection_of_info_point_12:
    "We shall be entitled to retain Your Personal Information and other information for such duration as may be required for the purposes specified hereunder or as may be otherwise required by the applicable law, and will be used by Us only in accordance with this Privacy Policy.",
  collection_of_info_point_2:
    "Information collected from third parties: We may also access Your telecom account, that You may have with Your telecom provider. From Your telecom account, We collect information such as your name, address, amount of bills paid by You, activation date, handset details. We collect such data from Your telecom operator for the purpose of verification of your identity and for profiling purpose. Such information is collected by Us through a third party, who facilities the collection of such data from Your telecom service provider.",
  collection_of_info_point_3:
    "Location based information: When and if You download and/or use the Platform through Your mobile, We may receive information about Your location, Your IP address, and/or Your mobile device, including a unique identifier number for Your device. We may use this information to provide You with location-based Services including but not limited to search results and other personalized content. You can withdraw Your consent at any time by disabling the location-tracking functions on Your device. However, this may affect Your enjoyment of certain functionalities on the Platform. In addition to the above, We may identify and use Your IP address to also help diagnose problems with Our server, resolve such problems and administer the Platform. Your IP address is also used to help identify you and to gather broad demographic information.",
  collection_of_info_point_4:
    "{x15} We may also collect certain non-personal information, such as Your internet protocol address, web request, operating system, browser type, URL, internet service provider, IP address, aggregate user data, browser type, software and hardware attributes, pages You request, and cookie information, etc. which will not identify with You specifically (“Non - Personal Information”), while You browse, access or use the Platform. We receive and store Non – Personal Information by the use of data collection devices such as “cookies” on certain pages of the Platform, in order to help and analyze Our web - page flow, track user trends, measure promotional effectiveness, and promote trust and safety. We offer certain additional features on the Platform that are only available through use of a “cookie”. We place both permanent and temporary cookies in Your computer’s hard drive.",
  collection_of_info_point_5:
    "Information collected through the Services availed: In the event You seek consultation related Services provided on the Platform, we shall collect and store the information provided by You to the advisor on the Platform, which may include details of the crop grown by You, details of Your land, finances relating to Your produce, etc. In the event You utilize the Services for the trading of the crops, we shall collect and store the details of such transactions.",
  collection_of_info_point_6:
    "User correspondence: If You choose to post messages on any communication channels provided by the Platform or leave feedback, We will collect and store such information You provide to Us. We retain this information as necessary to resolve disputes, provide customer support, respond to queries, and inquires, and troubleshoot problems and improve the Services. If You send us correspondence, such as emails or letters, or if other users or third parties send us correspondence about Your activities or postings on the Platform, We may collect and retain such information into a file specific to You for responding to Your request and addressing concerns in relation to Your use of the Platform.",
  collection_of_info_point_7:
    "All Your information, including Personal Information, collected by Us through the Platform, shall be referred to as ‘Information’.",
  collection_of_info_point_8:
    "All transactional information including financial information gathered by the Company shall be stored on servers, log files and any other storage system owned by third parties.",
  collection_of_info_point_9:
    "Our primary goal in doing so is to provide You a safe, efficient, smooth, and customized experience on the Platform. The information collected allows Us to provide the Services and features on the Platform that most likely meet Your needs, and to customize the Platform to make Your experience safer and easier. More importantly, while doing so, We collect the above - mentioned Personal Information from You that We consider necessary for achieving this purpose.",
  collection_of_info_subpoint_1:
    "In order to make/receive payments for the Services availed through the Platform, You may share financial information such as Your card details and bank account details.",
  collection_of_info_subpoint_2:
    "The above information is collectively referred to as (“{x14}”). In certain cases, the above information may be shared by You with the Company offline to access the Services.",
  collection_of_info_subpoint_3:
    "All information disclosed by You shall be deemed to be disclosed willingly and without any coercion. No liability pertaining to the authenticity/ genuineness/ misrepresentation/ fraud/ negligence of the information disclosed shall lie on the Company nor will the Company be in any way responsible to verify any information obtained from You. In the event You are aware of any changes or inaccuracies in Your Personal Information, You should inform the Company of such changes so that the records of the Company may be updated or corrected.",
  collection_of_information: "Collection of Information",
  grievance_officer: "Grievance Officer:",
  grievance_officer_desc_1:
    "If You have any grievance with respect to the Platform or the Services, You may write to the Grievance Redressal Officer, the name and contact details have been provided below:",
  grievance_officer_desc_2: "{x21} Mrudhukar Batchu",
  grievance_officer_desc_3: "{x22}: mrudhu@fruitx.com",
  grievance_officer_desc_4:
    "The Grievance Redressal Officer shall dispose off any complaint in accordance with the applicable laws.",
  hassle_free_fruit_sourcing_for_business:
    "आपके व्यापार के लिए फलों की आसानी से आपूर्ति",
  i_accept: "I accept",
  in_addition_to_the_above_information:
    "In addition to the above information and in order to avail Services through the Platform:",
  links_to_third_party:
    "Links to Other Third – Party Sites and collection of information:",
  links_to_third_party_desc_1:
    "Our Platform may link You to other third - party Platforms (“{x16}”) that may collect Your Personal Information including Your IP address, browser specification, or operating system. The Company is not in any manner responsible for the security of such information or their privacy practices or content of those Third – Party Sites. Additionally, You may also encounter “cookies”, “tokens” or other similar devices on certain pages of the Third – Party Sites and it is hereby clarified by the Company that the Platform does not control the use of cookies by these Third – Party Sites. These third-party service providers and Third-Party Sites may have their own privacy policies governing the storage, retention and security of Your information that You may be subject to. This Privacy Policy does not govern and the Company is not liable for any information provided to, stored on, or used by these third-party providers and Third-Party Sites. We recommend that when You enter a Third-Party Site, You review the Third-Party Site’s privacy policy as it relates to safeguarding of Your information. We may also use third-party advertising companies to serve ads when You visit the Platform. These companies may use information (not including Your name, address, email address, or telephone number) about Your visits to the Platform and Third-Party Sites in order to provide advertisements about goods and services of interest to You. You agree and acknowledge that We are not liable for the information published in search results or by any Third-Party Sites",
  non_personal_and_autonatic_information:
    "Non – Personal and Automatic Information:",
  on_discussion_boards:
    "on discussion boards, in messages and chat areas, etc.",
  personal_information: "Personal Information",
  policy_towards_children: "Policy Towards Children",
  policy_towards_children_desc_1:
    "The Platform is intended for a general audience and not for use by anyone younger than the age of 18. The Company does not knowingly collect personal information from children younger than the age of 18 without the consent of a parent or legal guardian; if the Company learns that it has done so, the Company will promptly remove the information from all active databases. If you are a parent or legal guardian who believes that the Company has collected or used their child’s personal information, please contact the Company at contact@fruitx.com.",
  privacy_policy: "Privacy Policy",
  privacy_policy_company_name: "CHIFU AGRITECH PRIVATE LIMITED",
  privacy_policy_description_1:
    "{x1} {x2}, a company incorporated under the Companies Act, 2013, with its registered office at 8-5-119/5, Mallikarjuna Colony, Road No 3, Old Bowen Pally, Kukat Pally, Hyderabad, Andhra Pradesh - 500011, India which owns and operates the website https://in.fruitx.com/ and mobile application FruitX, Vegrow (collectively referred to as “{x3}”) is committed to protecting your (“{x4}” or “{x5}”, as applicable) privacy and the information that You share while using the Platform. We value the trust You place in Us. That’s why, We maintain reasonable security standards for securing the transactions and Your information.",
  privacy_policy_description_2:
    "This privacy policy (“{x6}”) specifies the manner in which personal data and other information is collected, received, stored, processed, disclosed, transferred, dealt with or otherwise handled by the Company. This Privacy Policy does not apply to information that You provide to, or that is collected by, any third-party through the Platform, and any Third-Party Sites (defined hereinafter) that You access or use in connection with the Services (defined hereinafter) offered on the Platform.",
  privacy_policy_description_3:
    "Please read the Privacy Policy carefully prior to using or registering on the Platform or accessing any material, information or availing any services through the Platform including services in relation to farming advisory and sale or purchase of crops offered through the Platform (“{x7}”).",
  privacy_policy_description_4:
    "By visiting the Platform or setting up/creating an account on the Platform for availing the Services or by sharing a one-time password generated via SMS with Us, You (“{x9}”, “{x10}” {x11}) accept and agree to be bound by the terms and conditions of the Privacy Policy as well as Our Terms of Use (“{x12}”), which are incorporated herein by reference. This Privacy Policy is also is incorporated into and subject to Our [Terms] and shall be read harmoniously and in conjunction with the [Terms].",
  privacy_policy_description_5:
    "This Privacy Policy (i) will be considered to be an electronic record under the Indian data privacy laws including the Information Technology Act, 2000 read with rules and regulations made thereunder and (ii) will not require any physical, electronic, or digital signature by the Company or by You.",
  privacy_policy_referrer: '(“Company”, "We", "Us", or "Our")',
  public_posts: "Public Posts:",
  public_posts_desc_1:
    "You may provide Your feedback, reviews, testimonials, etc. on the Platform on Your use of the Services provided by the Company or any other communication on the communication channel provided by the Platform (“{x17}”). Any content or Personal Information and Posts that You share or upload on the publicly viewable portion of the Platform ({x18}) will be publicly available, and can be viewed by other users. Your Posts shall have to comply with the provisions of the Terms. The Company retains an unconditional right to remove and delete any Post or such part of the Post that, in the opinion of the Company, does not comply with the conditions in the Terms. The Company reserves the right to use, reproduce and share Your Posts for any purpose. If You delete Your Posts from the Platform, copies of such Posts may remain viewable in archived pages, or such Posts may have been copied or stored by other Users.",
  questions: "Questions:",
  questions_desc_1:
    "Please feel free to contact us at contact@fruitx.com regarding any questions on the Privacy Policy.",
  security_precautions_and_measures: "Security Precautions and Measures:",
  security_precautions_and_measures_desc_1:
    "Our Platform has adopted all reasonable security measures and safeguards to protect Your privacy and Personal Information from loss, misuse, unauthorized access, disclosure, destruction, and alteration of the information in compliance with applicable laws. Further, whenever You change or access Your account on the Platform or any information relating to it, We offer the use of a secure server. As long as You access and/or use the Platform (directly or indirectly) it is Your obligation, at all times, to take adequate physical, managerial, and technical safeguards, at Your end, to preserve the integrity and security of Your data which shall include and not be limited to Your Personal Information.",
  security_precautions_and_measures_desc_2:
    "When payment information is being transmitted on or through the Platform (if any), it will be protected by encryption technology. You expressly consent to the sharing of Your information with third party service providers, including payment gateways, to process payments and manage your payment-related information. Hence, the Company cannot guarantee that transmissions of Your payment-related information or Personal Information will always be secure or that unauthorized third parties will never be able to defeat the security measures taken by the Company or the Company’s third-party service providers. The Company or any of its group entities do not assume any liability or responsibility for disclosure of Your information due to errors in transmission, unauthorized third-party access, or other causes beyond its control. You play an important role in keeping Your personal information secure. You shall not share Your Personal Information or other security information for Your account with anyone. The Company has undertaken reasonable measures to protect Your rights of privacy with respect to Your usage of the Platform controlled by Us and Our Services. However, We shall not be liable for any unauthorized or unlawful disclosures of Your personal information made by any third parties or any other user of the Platform.",
  sharing_of_info_desc_1:
    "We may also disclose Your Information (such as name, date of birth, contact and address details, PAN, bank statements etc.), to the extent necessary, to Our group entities, third party vendors, advisors, other service providers who work for the Company or provide Services through the Platform and are bound by contractual obligations to keep such Information confidential and use it only for the purposes for which We disclose it to them. This disclosure may be required for Us, for instance, to provide You access to Our Services including for providing advisory, for undertaking auditing or data analysis / data enrichment, for procuring credit scores / identity scores from credit rating or credit reporting agencies, or to prevent, detect, mitigate, and investigate fraudulent or illegal activities related to Our Services. We do not disclose Your Personal Information to third parties for their marketing and advertising purposes without Your explicit consent.",
  sharing_of_info_desc_2:
    "The Company may disclose Your information, to the extent necessary: (i) to comply with laws and to respond to lawful requests and legal process, (ii) to protect the rights and property of the Company, Our users, and others, including to enforce the Terms, and (iii) in an emergency to protect the personal safety and assets of the Company, the users, or any person. In such an event the Company is in no manner responsible for informing You or seeking Your approval or consent",
  sharing_of_info_desc_3:
    "We may, in compliance with applicable laws, share/ transfer/ assign all of Your Personal Information and other information with any other business entit(ies), in the event of a merger, sale, re-organization, amalgamation, joint ventures, assignment, restructuring of business or transfer or disposition of all or any portion of Our business.",
  sharing_of_information: "Sharing of Information:",
  terms: "Terms",
  third_party_sites: "Third – Party Sites",
  this_privacy_law_applies_to_the_following_information:
    "This Privacy Policy applies to the following information:",
  use_of_info_desc_1:
    "We use Your Information for the following: (i) to provide and improve the Services and features on the Platform that You request; (ii) to analyze and track Your progress and provide relevant guidance and feedback to You; (iii) to enhance Your experience by providing You with relevant suggestions; (iv) to resolve disputes and troubleshoot problems; (v) to help promote a safe service on the Platform and protect the security and integrity of the Platform, the Services and the users; (vi) to detect, prevent and protect Us and You from any errors, fraud and other criminal or prohibited activity on the Platform; (vii) to enforce and inform about our terms and conditions; (viii) to process and fulfil Your request for Services or respond to Your comments, and queries on the Platform; (ix) to contact You to enable access to the Services or to provide information in relation to the Services; (x) to communicate important notices or changes in the Services provided by the Company, use of the Platform and the terms which govern the relationship between You and Us; (xi) for research and analysis purpose; (xii) for the purpose of identity verification; and (xiii) for any other purpose after obtaining Your consent at the time of collection.",
  use_of_info_desc_2:
    "We may occasionally ask You to complete online surveys or questionaries. These surveys may ask You for Your contact information and demographic information (like zip code, age, or income level). We use Your Personal Information to send You promotional emails, however, We will provide You the ability to opt-out of receiving such emails from Us. If You opt out, the Company may still send You non-promotional emails, such as emails about the Services and Your account on the Platform. Unless and until, You explicitly give Your consent to Us, to do so, We will not share Your Personal Information with another user of the Platform and vice versa.",
  use_of_information: "Use of information:",
  widthdraw_of_consent: "Withdrawal of consent:",
  you: "You",
  your: "Your",
  your_consent_and_changes_to_privacy_policy:
    "Your Consent and Changes to Privacy Policy:",
  your_consent_and_changes_to_privacy_policy_desc_1:
    "{x19} You may choose to withdraw Your consent provided hereunder at any point in time. Such withdrawal of the consent must be sent in writing to contact@fruitx.com. In case You do not provide Your consent or later withdraw Your consent, We request you not to access the Platform and/or use the Services and We also reserve the right to not provide You any Services on the Platform. In such a scenario, the Company may delete Your information (personal or otherwise) or de-identify it so that it is anonymous and not attributable to You. In the event, the Company retains the Personal Information post withdrawal or cancellation of Your consent, it shall retain it only for the period permitted under applicable laws.",
  your_consent_and_changes_to_privacy_policy_desc_2:
    "{x20} We reserve the unconditional right to change, modify, add, or remove portions of this Privacy Policy at any time. While we will intimate you whenever we make material changes to our Privacy Policy, You can determine when this Privacy Policy was last modified by referring to the “Last Updated” legend above. It shall be Your responsibility to check the Privacy Policy periodically for changes. Your continued use and access of the Platform shall signify Your acceptance of the amended Privacy Policy and Your consent to be legally bound by the same.",
};
