import { translate } from "locale/formatter";

import { StyledTab, StyledTabs } from "./styled";

const CustomTabs = ({
  selectedTab,
  handleSelectedtab = () => {},
  label = ["auction", "sales"],
}) => {
  return (
    <StyledTabs value={selectedTab} onChange={handleSelectedtab}>
      {label.map((l) => {
        return <StyledTab label={translate(`${l}`)} key={l} />;
      })}
    </StyledTabs>
  );
};

export default CustomTabs;
