import React from "react";
import { isEmpty } from "lodash";

import { PrivacyPolicyConst } from "./const";

export const textFormatter = (textKey, dynamicValues = {}) => {
  const text = PrivacyPolicyConst[textKey];

  if (isEmpty(dynamicValues)) return text;

  let replacedText = text;

  Object.keys(dynamicValues).forEach((placeholder) => {
    replacedText = replacedText.replace(
      `{${placeholder}}`,
      `{{${placeholder}}}`
    );
  });

  const textParts = replacedText.split(/({{[a-zA-Z0-9_]+}})/);

  const jsxElements = textParts.map((part) => {
    if (part.match(/{{[a-zA-Z0-9_]+}}/)) {
      const placeholder = part.match(/[a-zA-Z0-9_]+/)[0];
      return (
        <React.Fragment key={placeholder}>
          {dynamicValues[placeholder]}
        </React.Fragment>
      );
    }
    return part;
  });

  return <div>{jsxElements}</div>;
};
