import { useEffect, useRef, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment } from "@mui/material";
import { useTheme } from "@mui/styles";
import {
  areAllLotsSold,
  getFirstTokenWithUnsoldLots,
} from "pages/auctioneer/platform/utils";
import { scrollToView } from "utils";

import {
  CustomTextField,
  SearchBox,
  TickImage,
  TokenBox,
  TokenSlider,
} from "./styled";

const ScrollSearch = ({
  data = [],
  handleTokenSelection = () => {},
  selectedTokenId = -1,
  auctionLots = {},
}) => {
  const [token, setToken] = useState("");
  const tokenRef = useRef();
  const { colors } = useTheme();
  useEffect(() => {
    if (token !== selectedTokenId) {
      const inputToken = selectedTokenId === -1 ? "" : selectedTokenId;
      setToken(inputToken);
    }
    if (selectedTokenId !== -1) {
      scrollToView(tokenRef);
    }
  }, [selectedTokenId]);

  useEffect(() => {
    if (selectedTokenId === -1) {
      const preselectToken = getFirstTokenWithUnsoldLots(data, auctionLots);
      if (preselectToken) {
        handleTokenSelection(String(preselectToken).padStart(2, "0"));
      }
    }
  }, [auctionLots, data]);

  const toggleTokenSelection = (item, isTokenSearched = false) => {
    if (selectedTokenId === item && !isTokenSearched) handleTokenSelection(-1);
    else if (selectedTokenId === item && isTokenSearched) return -1;
    else {
      const filteredToken = String(item);
      handleTokenSelection(filteredToken.padStart(2, "0"));
    }
  };

  return (
    <div className="flex justify-between">
      <TokenSlider>
        {data?.map((item) => (
          <TokenBox
            ref={+item === +selectedTokenId ? tokenRef : null}
            key={item}
            isSelected={+item === +selectedTokenId}
            onClick={() => toggleTokenSelection(item)}
            colors={colors}
          >
            <span className="mr-2">C </span>
            {item.padStart(2, "0")}
            {areAllLotsSold(auctionLots[item]) && (
              <TickImage name="CheckCircle" alt="check-circle" />
            )}
          </TokenBox>
        ))}
      </TokenSlider>
      <SearchBox data-cy="token_search">
        <CustomTextField
          colors={colors}
          type="number"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <b>C</b>{" "}
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment
                position="end"
                onClick={() => toggleTokenSelection(token || -1, true)}
              >
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          value={token}
          onChange={({ target }) => setToken(target.value)}
        />
      </SearchBox>
    </div>
  );
};

export default ScrollSearch;
