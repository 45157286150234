import { MONTH_MAPPER } from "const/date";
import { getStartOfToday } from "utils";

export const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const formatDate = (date = new Date()) => {
  const d = new Date(date);
  const year = d.getFullYear();
  const day = `${d.getDate()}`.padStart(2, "0");
  const month = `${d.getMonth() + 1}`.padStart(2, "0");

  return `${day} ${MONTH_MAPPER[+month]}, ${year}`;
};

export const toDateMonthYear = (sec) => {
  const date = new Date(sec);
  return date.toLocaleDateString();
};

export const getDiffDays = (start, end) => {
  return (
    (getStartOfToday(end) - getStartOfToday(start)) / (1000 * 60 * 60 * 24) + 1
  );
};

export const formatDateToDDMMYYYY = (date) => {
  if (date instanceof Date) {
    return [
      `${date.getDate()}`.padStart(2, "0"),
      `${date.getMonth() + 1}`.padStart(2, "0"),
      date.getFullYear(),
    ].join("/");
  }
};

export const getFormattedDateTime = (milliseconds) => {
  const date = new Date(milliseconds);

  // Get components of the date
  const day = date.getDate();
  const month = MONTHS[date.getMonth()];
  const year = date.getFullYear();
  let hours = date.getHours();
  const minutes = `0${date.getMinutes()}`.slice(-2);
  const period = hours < 12 ? "AM" : "PM";

  // Convert hours to 12-hour format
  hours = hours % 12 || 12;

  // Format the date and time string
  const formattedDateTime = `${day} ${month} ${year}, ${hours}:${minutes}${period}`;

  return formattedDateTime;
};

export const getFormattedTime = (milliseconds) => {
  const date = new Date(milliseconds);

  let hours = date.getHours();
  const minutes = `0${date.getMinutes()}`.slice(-2);
  const period = hours < 12 ? "AM" : "PM";
  // Convert hours to 12-hour format
  hours = hours % 12 || 12;

  // Format the date and time string
  const formattedDateTime = `${hours}:${minutes}${period}`;

  return formattedDateTime;
};
