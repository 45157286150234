import { forwardRef } from "react";
import { useDispatch } from "react-redux";
import moengage from "@moengage/web-sdk";
import LogoutIcon from "@mui/icons-material/Logout";
import { Button } from "@mui/material";
import useMoEngage from "hooks/useMoEngage";
import useTracker from "hooks/useTracker";
import { translate } from "locale/formatter";
import { actions } from "slices/app.slice";
import { HOME_SCREEN_EVENTS } from "utils/events";
import { removeUser } from "utils/localStorage";

import Text from "components/Text";

const LogoutButton = forwardRef((props, ref) => {
  const trackUser = useTracker();
  const dispatch = useDispatch();
  const tracker = useMoEngage();

  const handleLogout = async () => {
    const logoutSuccess = dispatch(actions.logout());
    removeUser();
    tracker(HOME_SCREEN_EVENTS.LOGOUT_CLICK, {
      LogoutStatus: logoutSuccess ? "Success" : "Failure",
    });
  };

  return (
    <Button
      ref={ref}
      onClick={() => {
        trackUser("l_click_logout");
        handleLogout();
        moengage.destroy_session();
      }}
      data-cy="home.logOutButton"
      className="flex gap-2 bg-white py-2 px-3 rounded-xl"
    >
      <Text className="flex gap-2 items-center !text-xl">
        <LogoutIcon />
        {translate("logout")}
      </Text>
    </Button>
  );
});

export default LogoutButton;
