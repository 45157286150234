import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedLotCardIndex: 0,
  filters: {},
  publicUrlFilters: {},
  QUALITY_GRADES_MAP: {},
  RANGE_SIZE_MAP: {},
  QUALITY_GRADES: [],
  RANGE_SIZE: [],
};

const slice = createSlice({
  name: "catalogue",
  initialState,
  reducers: {
    setSelectedLotCardIndex: (state, { payload }) => ({
      ...state,
      selectedLotCardIndex: payload,
    }),
    setFilters: (state, { payload }) => ({
      ...state,
      filters: payload,
    }),
    setPublicUrlFilters: (state, { payload }) => ({
      ...state,
      publicUrlFilters: payload,
    }),
    setGradeRangeFiltersData: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    },
  },
});

export const { actions } = slice;

export default slice.reducer;
