import { mandiService } from "./base";

export const bidderOutstanding = () => {
  return 1234 || mandiService.get("");
};

export const myBuys = async (auction, bidder_id) => {
  const auction_lots_won = await auction?.doc.ref
    .collection("auc_auction_lots")
    .where("auction_id", "==", auction.auction_id)
    .where("bidder_id", "==", bidder_id)
    .get()
    .then((res) => res.docs.map((doc) => doc.data()));

  let quantity_bought = 0;
  let total_price = 0;
  let units = 0;

  auction_lots_won?.forEach((lot) => {
    quantity_bought += lot.weight;
    total_price += lot.sold_price * lot.weight;
    units += lot.units;
  });

  const avg_price_per_lot = total_price / quantity_bought || 0;

  return {
    units,
    quantity_bought,
    avg_price_per_lot,
    total_price,
    lots_bought: auction_lots_won?.length,
    auction_lots_won,
  };
};
