import { Button } from "@mui/material";

import { ButtonProgress, Wrapper } from "./styled";

const AppButton = ({ children, loading, color = "primary", ...props }) => {
  return (
    <Wrapper>
      <Button disabled={loading} {...props} color={color}>
        {children}
      </Button>
      {loading && <ButtonProgress color={color} size={24} />}
    </Wrapper>
  );
};

export default AppButton;
