import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box, CircularProgress, Grid } from "@mui/material";
import { useTheme } from "@mui/styles";
import { translate } from "locale/formatter";
import { isEmpty } from "lodash";
import { actions } from "slices/app.slice";
import { actions as aucActions } from "slices/auction.slice";
import { settings } from "utils/const";
import { endAuction, restartBidding, returnToFarmer } from "utils/networkUtils";

import BidStepper from "components/BidStepper";
import Pills from "components/Pills";
import { PILL_TYPES } from "components/Pills/const";
import Text from "components/Text";

import ReturnToFarmerPopup from "../ReturnToFarmerPopup";

const AutoRedirectComp = ({ navigate, colors, callBackFn = () => {} }) => {
  const [secs, setSecs] = useState(settings.autoRedirectTime);
  useEffect(() => {
    const timer = setInterval(() => {
      if (secs > 0) setSecs(secs - 1);
      else if (secs <= 0) {
        clearInterval(timer);
        callBackFn();
        navigate(-1);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [secs, navigate]);

  return (
    <Text
      data-cy="timer"
      textType="secondary"
      className="mt-3 text-xs"
      color={colors.lightGray}
    >
      {translate("auto_redirect_in")} {secs}
      {translate("short_hand_of_seconds")}
    </Text>
  );
};

const Footer = ({
  remainingTime,
  maxBid,
  basePrice,
  setBasePrice,
  auctionedLot,
  isSold = false,
  nextLot,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { colors } = useTheme();

  const [showLoader, setShowLoader] = useState(false);
  const [showRetunToFarmerPopup, setShowReturnToFarmerPopup] = useState(false);

  const closeReturnToFarmerPoup = () => {
    setShowReturnToFarmerPopup(false);
  };

  const handleReturnToFarmer = () => {
    if (returnToFarmer(auctionedLot)) {
      setShowLoader(true);
      setTimeout(() => {
        setShowLoader(false);
        navigate(-1);
      }, settings.autoRedirectTime * 1000);
      dispatch(aucActions.isLotAvailableForBid(true));
    }
  };

  const setTokenSelection = () => {
    if (!nextLot?.token_id) return;

    dispatch(actions.setTokenId(String(nextLot.token_id).padStart(2, "0")));
  };

  const getButtonTextOnLotSold = () => {
    if (nextLot) {
      return `${nextLot.token_type}-${nextLot?.token_id} ${nextLot.grade}`;
    }
    return translate("back_to_grade_selection");
  };

  if (showLoader)
    return (
      <Box className="flex align-items-center justify-center text-black-50 mt-8">
        <CircularProgress style={{ color: colors.white }} />
      </Box>
    );

  if (!remainingTime && isSold) {
    return (
      <Box className="flex flex-column mt-8 justify-center align-items-center">
        <Text className="!text-white text-xs mb-1">{translate("next")}</Text>
        {!isEmpty(nextLot) && (
          <Pills
            type={PILL_TYPES.CONTAINED_WHITE}
            className="px-4 py-4 text-sm"
            handleOnClick={() => {
              setTokenSelection();
              navigate(-1);
            }}
          >
            <Text color={colors.primary}>{getButtonTextOnLotSold()}</Text>
          </Pills>
        )}
        <AutoRedirectComp
          navigate={navigate}
          colors={colors}
          callBackFn={setTokenSelection}
        />
      </Box>
    );
  }
  if (remainingTime && !isEmpty(maxBid)) {
    return (
      <Box className="flex mt-8 justify-center">
        <Pills
          type={PILL_TYPES.CONTAINED_WHITE}
          className="px-4 py-4 text-sm"
          handleOnClick={() => {
            const ended_by_timeout = false;
            const timeout_that_time = null; // not required as its a manual end
            const max_bid_ref_id = maxBid.id;
            const max_bid_placed_at = maxBid.created_at;

            endAuction(
              auctionedLot,
              maxBid.price,
              maxBid.bidder_ref_id,
              maxBid.short_code,
              maxBid.bidder_name,
              true,
              ended_by_timeout,
              timeout_that_time,
              max_bid_ref_id,
              max_bid_placed_at
            );
          }}
        >
          <Text color={colors.primary}>{translate("end_this_bid")}</Text>
        </Pills>
      </Box>
    );
  }
  if (!remainingTime && (!maxBid || isEmpty(maxBid))) {
    return (
      <>
        <Box className="mb-1 mt-8">
          <Text className="text-white opacity-40">
            {translate("change_starting_bid")}
          </Text>
        </Box>
        <Grid container className="flex mb-2 align-items-center">
          <Grid item xs={6} className="text-left">
            <BidStepper basePrice={basePrice} setBasePrice={setBasePrice} />
          </Grid>
          {(+basePrice > 0 || !!+basePrice) && (
            <Grid item xs={6} className="text-right">
              <Pills
                data-cy="restart_bidding"
                type={PILL_TYPES.CONTAINED_WHITE}
                className="px-4 py-4"
                handleOnClick={() => restartBidding(basePrice, auctionedLot)}
              >
                <Text className="font-bold text-sm" color={colors.primary}>
                  {translate("restart_bidding")}
                </Text>
              </Pills>
            </Grid>
          )}
        </Grid>
        <Box className="mt-8 flex justify-center">
          <Text
            data-cy="return_to_farmer"
            className="text-sm underline"
            color={colors.white}
            onClick={() => setShowReturnToFarmerPopup(true)}
          >
            {translate("return_to_farmer")} ?
          </Text>
        </Box>
        {!!showRetunToFarmerPopup && (
          <ReturnToFarmerPopup
            closePopup={closeReturnToFarmerPoup}
            showPopup={showRetunToFarmerPopup}
            handleReturnToFarmer={handleReturnToFarmer}
          />
        )}
      </>
    );
  }
  return null;
};

export default Footer;
