import { networkStrengthParams } from "utils/const";

import { getCurrTime } from "../utils/date";

export const PingsHistory = class {
  pings = [];

  pingsLookup = {};

  latestAckTime = getCurrTime();

  addPing = (ping) => {
    this.pings.push(ping);
    this.pingsLookup[ping.initiatedAt] = ping;
    this.clearOldPings();
  };

  clearOldPings = () => {
    if (this.pings.length <= networkStrengthParams.numOfPingsToAvg) return;

    const oldPing = this.pings.shift();
    delete this.pingsLookup[oldPing.initiatedAt]; // these two operates are not thread-safe, but okay for now.
  };

  handlePingsReceived = (pingDocuments) => {
    const pingsReceivedTime = getCurrTime();

    pingDocuments.forEach((pingDoc) => {
      const ping = this.getPingFromHistory(pingDoc); // ping might be missing if its removed from queue
      // console.log(pingDoc, ping, this.pingsLookup)
      if (ping && !ping.hasAcknowledged()) {
        ping.ackResponse(pingsReceivedTime);
        this.latestAckTime = pingsReceivedTime;
      }
    });
  };

  getPingFromHistory = (pingDoc) => {
    const ping = this.pingsLookup[pingDoc.initiatedAt];

    return ping;
  };

  computeLatency = () =>
    (
      this.pings.reduce((p, c) => p + c.getLatency(), 0) / this.pings.length
    ).toFixed(2);

  getPingsUnacknowledged = () =>
    this.pings.filter((x) => x.respondedAt == null).length;

  areEnoughPingsAcknowledged = () =>
    this.pings.length > 0 &&
    this.getPingsUnacknowledged() < networkStrengthParams.numOfPingsToAvg;

  getLatestAckTime = () => this.latestAckTime;
};
