import styled from "@emotion/styled";
import { Drawer } from "@mui/material";

// TODO can make it more generic based on anchor position

export const DrawerWrapper = styled(Drawer)`
  position: fixed;
  bottom: 3rem;
  right: 3rem;
  .MuiDrawer-paper {
    background-color: ${({ primaryBG }) =>
      primaryBG ? "rgba(44, 121, 115, 0.93)" : "white"};
    height: ${({ drawerHeight }) => drawerHeight || "25rem"};
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
  }
`;
