import { DateRange } from "react-date-range";
import styled from "@emotion/styled";

export const CustomDatepicker = styled(DateRange)(({ colors }) => ({
  ".rdrMonthsVertical": {
    alignItems: "center !important",
    backgroundColor: colors.smokeWhite,
    borderRadius: "1rem !important",
    padding: "8px !important",
  },
  ".rdrMonthAndYearWrapper": {
    borderBottom: `1px solid ${colors.cement}`,
  },
  ".rdrStartEdge, .rdrInRange, .rdrEndEdge": {
    backgroundColor: colors.primary,
  },
  border: `1px solid ${colors.cement}`,
  borderBottom: "none !important",
  boxShadow: `0px 4.02787px 7.04878px ${colors.darkSilver}`,
  ".rdrMonth": {
    padding: "0 0 4rem 0 !important",
  },
  "&.date-picker": {
    borderRadius: "1rem !important",
    backgroundColor: colors.smokeWhite,
  },
}));

export const CtaSection = styled.div(({ colors }) => ({
  padding: "0.5rem",
  ".ctaBtn": {
    padding: "0.5rem 1rem",
    color: `${colors.primary} !important`,
    border: `1px solid ${colors.primary}`,
    borderRadius: "1.5rem",
    position: "absolute",
    bottom: "20px",
    right: "10px",
    marginTop: "1rem",
  },
}));
