import { Backdrop, CircularProgress } from "@mui/material";
import { PropTypes } from "prop-types";

const Spinner = ({ color, size, isLoading, thickness, ...others }) => (
  <Backdrop open={isLoading} sx={{ color }} {...others}>
    <CircularProgress color="inherit" size={size} thickness={thickness} />
  </Backdrop>
);

Spinner.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
  isLoading: PropTypes.bool,
  thickness: PropTypes.number,
};

Spinner.defaultProps = {
  color: "black",
  size: "1.5rem",
  isLoading: false,
  thickness: 2,
};

export default Spinner;
