import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@mui/styles";
import { consentSend } from "services/termsAndCondition";
import { actions } from "slices/app.slice";
import { savePrivacyPolicy } from "utils/localStorage";
import { updatePolicyData } from "utils/networkUtils";

import AlertDialog from "components/Dialog/AlertDialog";
import Text from "components/Text";

import { PrivacyPolicyConst } from "./const";
import Content from "./Content";
import { classes, dialogStyle } from "./styled";

const TermsAndConditions = () => {
  const [showalert, setShowAlert] = useState(true);
  const dispatch = useDispatch();
  const { me } = useSelector((state) => state.app);

  const { privacy_policy, accept_and_continue } = PrivacyPolicyConst;

  const { aceeptButton } = classes();
  const { colors } = useTheme();

  const termsAgreedHandler = () => {
    savePrivacyPolicy("policyAccepted", true);
    consentSend({
      consent_for_id: me.customerId,
      verification_type: "app",
      verified_at: Date.now(),
    }).finally(() => {
      setShowAlert(false);
    });
    updatePolicyData(me);
  };

  const header = <Text color={colors.primary}>{privacy_policy}</Text>;
  const primaryBtnText = (
    <span className={aceeptButton}>{accept_and_continue}</span>
  );

  const policy = <Content />;

  return (
    <AlertDialog
      header={header}
      primaryBtntext={primaryBtnText}
      open={showalert}
      primaryBtnHandler={termsAgreedHandler}
      content={policy}
      dialogStyle={dialogStyle}
    />
  );
};

export default TermsAndConditions;
