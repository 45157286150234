import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, Link, useNavigate } from "react-router-dom";
import { Divider } from "@mui/material";
import { useTheme } from "@mui/styles";
import { AUCTION_STATUS } from "const/auctionStatus";
import { translate } from "locale/formatter";
import { myBuys } from "services/bidderNavbar";
import { actions as catalogueActions } from "slices/catalogue.slice";
import { getBidderAssociatedAuctions } from "utils";
import { PAGE_SRC, path } from "utils/const";
import { currency } from "utils/currencyFormatter";
import { useFirestore } from "utils/firebaseUtils/hooks/useFirestore";
import { getMandiId, setCurrentPage } from "utils/localStorage";

import { ImageIcons } from "components";
import NetworkBar from "components/NetworkBar";
import Text from "components/Text";

import { Header, HeaderWrapper, Wrapper } from "./styled";

const BackPageHandler = ({ src = "" }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { colors } = useTheme();
  const auctionDocs = useFirestore("auc_auctions").docs;

  const [boughtDetails, setBoughtDetails] = useState({});

  const { me } = useSelector((state) => state.app);
  const { lotAvailableToBid, currentAuction } = useSelector(
    (state) => state.auction
  );

  const todaysAuction = getBidderAssociatedAuctions(auctionDocs, me).find(
    (auction) => auction.mandi_id === getMandiId()
  );

  useEffect(() => {
    myBuys(todaysAuction, me.id).then((res) => setBoughtDetails(res));
  }, [todaysAuction, me, lotAvailableToBid]);

  const handleBackButton = () => {
    if (src === PAGE_SRC.CATALOGUE) {
      dispatch(catalogueActions.setFilters({}));
    }
    navigate(-1);
  };

  const { status, mandi_name, mandi_app_name } = currentAuction || {};

  return (
    <HeaderWrapper>
      <Header colors={colors}>
        <div className="flex gap-2">
          <ImageIcons
            name="backBtn"
            className="h-8 w-8"
            onClick={handleBackButton}
          />
          {status === AUCTION_STATUS.LIVE && (
            <Text>{mandi_app_name || mandi_name}</Text>
          )}
        </div>
        {status !== AUCTION_STATUS.LIVE && (
          <Text>{mandi_app_name || mandi_name}</Text>
        )}

        <div className="flex gap-2">
          <NetworkBar />

          <Wrapper colors={colors}>
            <Link
              to={me?.id && generatePath(path.todaysMyBuys, { id: me.id })}
              className="!text-black"
              onClick={() => setCurrentPage(src)}
            >
              <div className="flex items-center text-center gap-2">
                {currentAuction?.status === AUCTION_STATUS.LIVE && (
                  <div className="flex items-center gap-1 text-center">
                    <div className="flex flex-col">
                      <Text
                        className="font-bold"
                        size="10px"
                        color={colors.primary}
                      >
                        {boughtDetails.quantity_bought} {translate("Kgs")}
                      </Text>
                      <Text textType="secondary" size="10px">
                        {translate("quantity")}
                      </Text>
                    </div>
                    <div className="flex flex-col">
                      <Text
                        className="font-bold"
                        size="10px"
                        color={colors.primary}
                      >
                        {currency(boughtDetails.avg_price_per_lot)}
                      </Text>
                      <Text textType="secondary" size="10px">
                        {translate("avgPriceKg")}
                      </Text>
                    </div>
                  </div>
                )}
                <ImageIcons name="CartIcon" className="w-6 h-6" />
              </div>
            </Link>
          </Wrapper>
        </div>
      </Header>
      <Divider />
    </HeaderWrapper>
  );
};

export default BackPageHandler;
