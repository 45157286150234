import { useRef, useState } from "react";
import SwipeableViews from "react-swipeable-views";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import useTracker from "hooks/useTracker";
import { translate } from "locale/formatter";

import { ImageIcons } from "components";

import "./styles.css";

const AutoPlaySwipeableViews = SwipeableViews;
const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    position: "relative",
    height: "100%",
  },
  header: {
    backgroundColor: "transparent",
    textAlign: "center",
    width: "100%",
  },
  img: {
    height: "100%",
    display: "block",
    maxWidth: "100%",
    width: "100%",
    marginBottom: 0,
    marginRight: "auto",
    marginLeft: "auto",
  },
  rightButton: {
    top: "2rem",
    position: "absolute",
    right: "-2.5rem",
  },
  leftButton: {
    top: "2rem",
    position: "absolute",
    left: "-2.5rem",
  },
}));

const Carousel = ({
  imageData,
  isLoggedInUser = true,
  mandi_name = "",
  showLotInfo = true,
}) => {
  const theme = useTheme();
  const classes = useStyles();

  const zoomRef = useRef(false);
  const trackUser = useTracker();
  const interval = useRef();
  const [imageScale, setImageScale] = useState({});
  const [activeStep, setActiveStep] = useState(0);

  const handleStepChange = (item) => {
    const eventName = isLoggedInUser
      ? "l_horizontal_swipe"
      : "p_horizontal_swipe";

    trackUser(eventName, {
      mandi_name,
    });
    setActiveStep(item);
  };

  const handleScale = (imageIndex, scale) => {
    const currentScale = Math.ceil(scale);
    if (interval.current) {
      clearTimeout(interval);
    }

    interval.current = setTimeout(() => {
      setImageScale((s) => {
        // render page again only if image is scaled for the first time or zoomed out to normal
        if (
          s[imageIndex] === currentScale ||
          (s[imageIndex] > 1 && currentScale > 1)
        )
          return s;
        return {
          ...s,
          [imageIndex]: currentScale,
        };
      });
    }, 500);
  };

  const handleZoomState = (e, i) => {
    const {
      state: { scale },
    } = e;
    handleScale(i, scale);
    const eventName = isLoggedInUser ? "l_user_zoom" : "p_user_zoom";
    if (scale > 1 && !zoomRef.current) {
      zoomRef.current = true;
      trackUser(eventName, {
        mandi_name,
      });
    }
  };

  return (
    imageData?.ack_docs?.length > 0 && (
      <div className={classes.root} style={{ height: "100%" }}>
        <AutoPlaySwipeableViews
          enableMouseEvents
          index={activeStep}
          onChangeIndex={handleStepChange}
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          {imageData?.ack_docs?.map((item, index) => (
            <div key={index} className="h-full flex items-center">
              {Math.abs(activeStep - index) <= 2 ? (
                <TransformWrapper
                  panning={{
                    disabled: !imageScale[index] || imageScale[index] <= 1,
                  }}
                  onTransformed={(e) => handleZoomState(e, index)}
                >
                  <TransformComponent>
                    <div className="relative">
                      <img
                        alt="doc"
                        src={item?.acknowledgement_slip}
                        className="h-full w-screen"
                      />
                      {showLotInfo &&
                        imageData?.lot_details?.status === "Sold" && (
                          <>
                            <div className="soldStrip">
                              <ImageIcons name="RedStrip" />
                            </div>
                            <div className="soldPrice">
                              <sup className="text-[10px]">
                                {translate("INR")}
                              </sup>
                              <b>{imageData?.lot_details?.selling_price}</b>
                            </div>
                          </>
                        )}
                    </div>
                  </TransformComponent>
                </TransformWrapper>
              ) : null}
            </div>
          ))}
        </AutoPlaySwipeableViews>
      </div>
    )
  );
};

export default Carousel;
