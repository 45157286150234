import React from "react";
import { useTheme } from "@mui/styles";

import { ImageIcons } from "components";

export const useImageAndColor = (mandiProduct) => {
  const { colors } = useTheme();
  const product = mandiProduct?.split(",")?.length;
  const productName = product > 1 ? "multifruit" : mandiProduct;

  let imageName;
  let bgColor;

  switch (productName) {
    case "Pomegranate":
      imageName = "Pomo";
      bgColor = colors.babyPink;
      break;
    case "India Apple RD":
      imageName = "Apple";
      bgColor = colors.lightBrown;
      break;
    case "Indian Apple Gala":
      imageName = "Apple";
      bgColor = colors.lightBrown;
      break;
    case "Mango":
      imageName = "Mango";
      bgColor = colors.orangeYellow;
      break;
    default:
      imageName = "MultiFruit";
      bgColor = colors.lightGreen2;
      break;
  }

  return { imageTag: <ImageIcons name={imageName} />, bgColor };
};
