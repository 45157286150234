const { makeStyles } = require("@mui/styles");

export const classes = makeStyles(({ colors }) => ({
  bodyWrapper: {
    display: "grid !important",
    placeItems: "center !important",
  },
  timerClass: {
    fontSize: "4.7rem",
    marginTop: "0.4rem !important",
  },
  commonClass: {
    fontSize: "1.6rem !important",
    color: `${colors.primary} !important`,
  },
  subHeader: {
    fontSize: "1rem !important",
    color: `${colors.gravel} !important`,
  },
}));

export const dialogContentStyle = {
  padding: "1.5rem 2.5rem 0.7rem 2.5rem !important",
};

export const dialogStyle = {
  borderRadius: "1.5rem",
};
