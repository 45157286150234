import { AUCTION_STATUS } from "const/auctionStatus";
import firebase from "firebase";

import {
  collectionGroup,
  db,
  dbFieldValue,
  updateDocument,
} from "./firebaseUtils/api";
import { settings } from "./const";

export const handleEnterAuctionButton = async (auction, status = "Live") => {
  const auc = await getAuction(auction);

  await db.runTransaction(async (t) => {
    if (auc != null) {
      await t.update(auc.ref, { status });
    }
  });

  if (status === AUCTION_STATUS.LIVE) {
    await resetAllCurrentLots(auction.auction_id);
    await resetAllShowLots(auction.auction_id);
  }
};

const getAuction = async (auction) => {
  const aucDocRef = await db
    .collection("auc_auctions")
    .where("auction_id", "==", auction?.auction_id)
    .get();

  const aucDocs = await aucDocRef.docs;

  if (aucDocs.length > 0) return aucDocs[0].ref.get();

  return null;
};

export const resetAllShowLots = async (auction_id) => {
  const auctionLots = await collectionGroup("auc_auction_lots")
    .where("show_lot", "==", true)
    .where("auction_id", "==", auction_id)
    .get();

  const promises = [];
  auctionLots.docs.forEach((doc) =>
    promises.push(
      updateDocument(doc, {
        show_lot: false,
      })
    )
  );
  await Promise.all(promises); // use batch writes.
};

export const resetAllCurrentLots = async (auction_id) => {
  const auctionLots = await collectionGroup("auc_auction_lots")
    .where("is_current", "==", true)
    .where("auction_id", "==", auction_id)
    .get();

  const promises = [];
  auctionLots.docs.forEach((doc) =>
    promises.push(
      updateDocument(doc, {
        is_current: false,
        auction_ended_at: dbFieldValue.serverTimestamp(),
      })
    )
  );
  await Promise.all(promises); // use batch writes.
};

const beginAuction = async (basePrice, lot) => {
  // Given the auction timer is running in BG, skipping these for now.
  // await resetAllCurrentLots(lot.auction_id);
  // await resetAllShowLots(lot.auction_id);

  await db.runTransaction(async (t) => {
    await t.update(lot.doc.ref, {
      base_price: basePrice,
      auction_started_at: dbFieldValue.serverTimestamp(),
      updated_at: dbFieldValue.serverTimestamp(),
      is_current: true,
      sold_price: null,
      bidder_id: null,
      short_code: null,
      bidder_name: null,
      timeout_that_time: null,
      max_bid_ref_id: null,
      max_bid_placed_at: null,
      ended_by_timeout: false,
      auction_ended_at: null,
    });
  });

  return true;
};

export const handleStartBidClick = async (startingBid, lot) => {
  try {
    await beginAuction(startingBid, lot);
  } catch (err) {
    console.log("# SOmething went wrong on clicking start bid button", err);
  }
};

export const endAuction = async (
  lot,
  price,
  bidder_ref_id = "",
  short_code = "",
  bidder_name = "",
  show_lot = true,
  ended_by_timeout = false,
  timeout_that_time = settings.elapseWindow,
  max_bid_ref_id = null,
  max_bid_placed_at = null
) => {
  updateDocument(lot.doc, {
    sold_price: price,
    bidder_id: bidder_ref_id,
    short_code,
    bidder_name,
    show_lot,
    is_current: false,
    auction_ended_at: dbFieldValue.serverTimestamp(),
    ended_by_timeout,
    timeout_that_time,
    max_bid_ref_id,
    max_bid_placed_at,
  });

  if (show_lot) {
    setTimeout(() => {
      updateDocument(lot.doc, {
        show_lot: false,
      });
    }, settings.showLotTime * 1000);
  }
};

export const restartBidding = async (basePrice, lot) => {
  try {
    await beginAuction(basePrice, lot);
  } catch (err) {
    console.log("# SOmething went wrong on clicking restart bidding", err);
  }
};

export const returnToFarmer = async (lot = {}) => {
  await db.runTransaction(async (t) => {
    const lot_to_be_returned = lot?.doc?.ref;
    await t.update(lot_to_be_returned, {
      return_to_farmer: true,
      bidder_id: null,
      sold_price: null,
      bidder_name: "return to farmer",
      short_code: null,
      ended_by_timeout: false,
      auction_ended_at: null,
      timeout_that_time: null,
      max_bid_ref_id: null,
      max_bid_placed_at: null,
    });
  });
  return true;
};

export const reopenAuction = async (lot = {}) => {
  await db.runTransaction(async (t) => {
    const lot_to_be_reauctioned = lot?.doc?.ref;
    const auction_biddings = await lot_to_be_reauctioned
      .collection("auc_auction_biddings")
      .get();
    await t.update(lot_to_be_reauctioned, {
      base_price: null,
      auction_started_at: null,
      bidder_id: null,
      sold_price: null,
      short_code: null,
      return_to_farmer: false,
      bidder_name: null,
      ended_by_timeout: null,
      auction_ended_at: null,
      timeout_that_time: null,
      max_bid_ref_id: null,
      max_bid_placed_at: null,
      is_current: false, // may not be required.
    });
    const promises = [];
    auction_biddings?.docs.forEach((doc) => promises.push(t.delete(doc.ref)));
    await Promise.all(promises); // use batch writes.
  });
  return true;
};

export const placeBid = async (
  lot,
  maxBidPrice,
  me,
  incrementValue,
  aucRefId
) => {
  await db.runTransaction(async (t) => {
    const col = lot.doc.ref.collection("auc_auction_biddings");
    const currentPrice = maxBidPrice || 0;
    await t.set(col.doc(), {
      lot_id: lot.lot_id,
      lot_ref_id: lot.id,
      auction_id: lot.auction_id,
      bidder_ref_id: me.id,
      bidder_name: me.fullName,
      short_code: me.shortCode ?? "",
      auction_ref_id: aucRefId,
      price: +currentPrice + incrementValue,
      created_at: dbFieldValue.serverTimestamp(),
      updated_at: dbFieldValue.serverTimestamp(),
    });
  });
};

export const updatePolicyData = (me) => {
  const { id } = me;
  const collectionRef = firebase.firestore().collection("users");
  const documentRef = collectionRef.doc(id);
  documentRef.update({ ...me, isPolicyAccepted: true });
};
