import { DrawerWrapper } from "./styled";

const DrawerSheet = ({
  children,
  open,
  onClose,
  position = "bottom",
  primaryBG = false,
  drawerHeight,
}) => {
  return (
    <DrawerWrapper
      open={open}
      onClose={onClose}
      anchor={position}
      primaryBG={primaryBG}
      drawerHeight={drawerHeight}
    >
      {children}
    </DrawerWrapper>
  );
};

export default DrawerSheet;
