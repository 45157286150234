import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import uuid from "react-uuid";
import { useHasFocus } from "hooks/useHasFocus";

import { LatencyCalculator } from "./controller/LatencyCalculator";

let latencyCalculator = null;

const useLatencyCalculator = () => {
  const { me } = useSelector((state) => state.app);
  const [latency, setLatency] = useState(0);
  const focus = useHasFocus();

  useEffect(() => {
    if (me.id && !latencyCalculator) {
      const userId = me.id;
      const sessionId = uuid();

      latencyCalculator = new LatencyCalculator(userId, sessionId, setLatency);
      latencyCalculator.startPinging();
    }

    return () => {
      latencyCalculator.stopPinging();
      latencyCalculator = null;
    };
  }, [me]);

  useEffect(() => {
    if (latencyCalculator) {
      if (focus) {
        latencyCalculator.resumePinging();
      } else {
        latencyCalculator.pausePinging();
      }
    }

    return () => {};
  }, [focus]);

  return latency;
};

export default useLatencyCalculator;
