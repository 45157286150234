import { lazy, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { actions as auctionActions } from "slices/auction.slice";
import { path } from "utils/const";

const TVScreen = lazy(() => import("pages/TV-Screen"));
const ReadOnlyCatalogue = lazy(() => import("pages/catalogue-readOnly"));

const LotImagePageView = lazy(() =>
  import("pages/catalogue/wishlist/components/lotImagesPageView")
);

const NonAuthenticatedRoutes = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(auctionActions.loadAuctions());
  }, []);

  const { tvScreen, catalogueReadOnly, lotImageViewer } = path;

  return (
    <Routes>
      <Route exact path={tvScreen} element={<TVScreen />} />
      <Route exact path={lotImageViewer} element={<LotImagePageView />} />
      <Route exact path={catalogueReadOnly} element={<ReadOnlyCatalogue />} />
    </Routes>
  );
};

export default NonAuthenticatedRoutes;
