import { useTheme } from "@mui/styles";

import Text from "components/Text";
import { TEXT_TYPES } from "components/Text/const";

import { formatTime } from "./utils";

const Timer = ({ seconds, color }) => {
  const { colors } = useTheme();
  const { ss = "00", ms = "00" } = formatTime(seconds ?? 0);
  return (
    <Text
      textType={TEXT_TYPES.PRIMARY}
      style={{ fontSize: "24px", color: color || colors.white }}
    >
      {Math.ceil(ss).toString().padStart(2, "0")}s :{" "}
      {ms.toString().padStart(2, "0")}ms
    </Text>
  );
};

export default Timer;
