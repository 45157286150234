import { useFirestore } from "utils/firebaseUtils/hooks/useFirestore";

import { getCurrentVersion } from "./CurrentVersion";

const checkUpdates = (currentVersion, remoteVersions) => {

  let rVersion;
  if(remoteVersions.length > 0) {
    rVersion = remoteVersions[0].version;
  } else {
    rVersion = 0;
  }
  if(currentVersion == 0 || rVersion == 0) {
    return false; // this should never happen. having this case incase if there is any issue in version computation
  }
  if(currentVersion < rVersion)
    return true;

  return false;

}

const VersionManager = () => {

  const currentVersion = getCurrentVersion();
  const remoteVersions = useFirestore("app_versions", (col) => col.orderBy("version", "desc").limit(1)).docs; // Todo - move this to db-layer.
  const hasUpdates = checkUpdates(currentVersion, remoteVersions);

  // keeping this util this gets stabilized.
  console.log(`Current version: ${currentVersion}`)
  console.log(`Remote version: ${remoteVersions[0]?.version}`)
  console.log(`Has updates?: ${hasUpdates}`)

  if(hasUpdates) {
    window.location.reload(true);
  }

  return (
    <>
    </>
  );
};

export default VersionManager;