import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Capacitor } from "@capacitor/core";
import moengage from "@moengage/web-sdk";
import { MoECapacitorCore } from "capacitor-moengage-core";

const useMoEngage = () => {
  const { me } = useSelector((state) => state.app);

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      MoECapacitorCore.addListener("pushTokenGenerated", (data) => {
        console.log(
          `${
            this.tag
          } Received callback 'pushTokenGenerated',  data: ${JSON.stringify(
            data
          )}`
        );
      });
      MoECapacitorCore.addListener("pushClicked", (data) => {
        console.log(
          `${this.tag} Received callback 'pushClicked',  data: ${JSON.stringify(
            data
          )}`
        );
      });

      MoECapacitorCore.addListener("inAppCampaignShown", (data) => {
        console.log(
          `${
            this.tag
          } Received callback 'inAppCampaignShown',  data: ${JSON.stringify(
            data
          )}`
        );
      });

      MoECapacitorCore.addListener("inAppCampaignDismissed", (data) => {
        console.log(
          `${
            this.tag
          } Received callback 'inAppCampaignDismissed',  data: ${JSON.stringify(
            data
          )}`
        );
      });

      MoECapacitorCore.addListener("inAppCampaignClicked", (data) => {
        console.log(
          `${
            this.tag
          } Received callback 'inAppCampaignClicked',  data: ${JSON.stringify(
            data
          )}`
        );
      });

      MoECapacitorCore.addListener("inAppCampaignCustomAction", (data) => {
        console.log(
          `${
            this.tag
          } Received callback 'inAppCampaignCustomAction',  data: ${JSON.stringify(
            data
          )}`
        );
      });

      MoECapacitorCore.addListener("inAppCampaignSelfHandled", (data) => {
        console.log(
          `${
            this.tag
          } Received callback 'inAppCampaignSelfHandled',  data: ${JSON.stringify(
            data
          )}`
        );

        MoECapacitorCore.selfHandledShown(data);
        MoECapacitorCore.selfHandledClicked(data);
        MoECapacitorCore.selfHandledDismissed(data);
      });

      MoECapacitorCore.initialize({
        appId: process.env.REACT_APP_MOENGAGE_APP_ID,
      });
      MoECapacitorCore.setupNotificationChannelsAndroid();
    }
  }, []);

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      MoECapacitorCore.initialize({
        appId: process.env.REACT_APP_MOENGAGE_APP_ID,
      });
      MoECapacitorCore.setUniqueId({
        uniqueId: me.email,
        appId: process.env.REACT_APP_MOENGAGE_APP_ID,
      });
      MoECapacitorCore.setEmailId({
        emailId: me.email,
        appId: process.env.REACT_APP_MOENGAGE_APP_ID,
      });

      MoECapacitorCore.setFirstName({
        firstName: me.fullName,
        appId: process.env.REACT_APP_MOENGAGE_APP_ID,
      });

      MoECapacitorCore.requestPushPermissionAndroid();
      MoECapacitorCore.pushPermissionResponseAndroid({ isGranted: true });
      MoECapacitorCore.updatePushPermissionRequestCountAndroid({
        appId: process.env.REACT_APP_MOENGAGE_APP_ID,
        count: 2,
      });

      MoECapacitorCore.setupNotificationChannelsAndroid();
    } else {
      moengage.add_email(me.email);
      moengage.add_unique_user_id(me.email);
      moengage.add_first_name(me.fullName);
    }
  }, [me]);

  const tracker = (event, params) => {
    if (Capacitor.isNativePlatform()) {
      MoECapacitorCore.trackEvent({
        eventName: event,
        appId: process.env.REACT_APP_MOENGAGE_APP_ID,
      });
    } else {
      moengage.track_event(event, params);
    }
  };

  return tracker;
};

export default useMoEngage;
