import { useEffect, useState } from "react";
import { firestore } from "utils/firebaseUtils/firebase";

const defaultQuery = (col) => col;

// To ensure all our queries to have strong data consistensy.
const fullyCommitted = (snap, isDoc) => {
  if (snap.metadata.fromCache) {
    return false;
  }

  if (snap.metadata.hasPendingWrites) {
    return false;
  }

  const docs = [];

  // When dealing with timestamp writes, listeners get notified twice,
  if (isDoc) {
    if (snap.data()) docs.push(snap.data());
  } else {
    snap.forEach((doc) => {
      docs.push(doc.data());
    });
  }
  let hasNull = false;
  docs.forEach((doc) => {
    if (!doc.updated_at) hasNull = true;
  });

  if (hasNull) return false;

  return true;
};

export const subscribeToDoc = (query, subscriber) => {
  try {
    const unsubscribe = query.onSnapshot(
      { includeMetadataChanges: true },
      (snap) => {
        if (!fullyCommitted(snap, true)) return;
        subscriber(snap.data());
      }
    );
    return unsubscribe;
  } catch (err) {
    console.log("Unable to subscribe: ", err.message);
    return () => {};
  }
};

export const subscribeToDocs = (query, subscriber) => {
  try {
    const unsubscribe = query.onSnapshot(
      { includeMetadataChanges: true },
      (snap) => {
        if (!fullyCommitted(snap, false)) return;

        const documents = [];

        snap.forEach((doc) => {
          // console.log(doc.data())
          documents.push({ ...doc.data(), id: doc.id, doc });
        });
        subscriber(documents);
      }
    );

    return unsubscribe;
  } catch (err) {
    console.log("Unable to subscribe: ", err.message);
    return () => {};
  }
};

export const useFirestore = (collection, query = defaultQuery) => {
  const [docs, setDocs] = useState([]);

  useEffect(() => {
    const fullQuery = query(firestore.collection(collection));
    const unsubscribe = subscribeToDocs(fullQuery, (documents) => {
      setDocs(documents);
    });

    return () => unsubscribe();
  }, [collection]);

  return { docs };
};
