import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { generatePath, Link } from "react-router-dom";
import { Grid } from "@mui/material";
import { useTheme } from "@mui/styles";
import { translate } from "locale/formatter";
import GradeTypeTag from "pages/catalogue/components/GradeTypeTag";
import { getCurrentLotForBidder } from "slices/auction.query";
import { path, settings } from "utils/const";

import { ImageIcons } from "components";
import Pills from "components/Pills";
import { PILL_TYPES } from "components/Pills/const";
import Text from "components/Text";

import { AuctionStatusBanner, styles } from "./styled";

const CatalogueDetails = () => {
  const { colors } = useTheme();
  const defaultStyle = styles();
  const state = useSelector((state) => state);
  const {
    auction: aucManager,
    catalogue: { RANGE_SIZE_MAP },
  } = state;
  const currentLot = getCurrentLotForBidder(aucManager);
  const [showSoldLot, setShowSoldLot] = useState(false);
  const { currentAuction } = aucManager;

  const {
    token_id = "",
    token_type = "",
    grade = "",
    weight = "",
    units = "",
    range_size = "",
    base_price = 0,
    sold_price,
    maxBid,
  } = currentLot || {};

  const sizeRange = translate(RANGE_SIZE_MAP[range_size]);
  const { text, currentBid, currentBidContainer, sup, soldTag } = defaultStyle;

  useEffect(() => {
    if (maxBid) {
      setShowSoldLot(true);
      return;
    }
    const timer = setTimeout(() => {
      setShowSoldLot(false);
    }, settings.autoRedirectTime * 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [maxBid]);

  if (currentLot === null && !showSoldLot) {
    return <></>;
  }
  const lotStatus = Boolean(sold_price);

  return (
    <Link
      to={
        currentLot &&
        generatePath(path.bidderPlatform, { id: currentAuction.id })
      }
    >
      <AuctionStatusBanner colors={colors}>
        <Grid container className="flex items-center py-1">
          <Grid item xs={9} className="pl-2">
            <Grid container>
              <Text className={text}>
                {token_type}-{token_id} {grade}
              </Text>
              <GradeTypeTag className="!rounded-md" />
            </Grid>
            <Grid container>
              <Grid xs={6}>
                <Grid container>
                  <Text style={{ color: colors.white }} className="mb-1">
                    {weight}
                    <Text color={colors.white} size="11px">
                      {translate("kg")}{" "}
                    </Text>
                    &#x2022; {units}
                    <Text color={colors.white} size="11px">
                      {translate("units")}
                    </Text>
                  </Text>
                </Grid>
                {!!range_size && (
                  <Pills
                    type={PILL_TYPES.CONTAINED_SECONDARY}
                    style={{ width: "fit-content" }}
                  >
                    <Text color={colors.white}>{sizeRange}</Text>
                  </Pills>
                )}
              </Grid>
              {lotStatus && (
                <Grid xs={6}>
                  <ImageIcons name="SoldPin" className={soldTag} />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={3} className={currentBidContainer}>
            <Text color={colors.white}>
              {lotStatus ? translate("sold_at") : translate("current_bid")}
            </Text>
            <sup className={sup}>₹</sup>
            <Text className={currentBid}>{maxBid?.price || base_price}</Text>
          </Grid>
        </Grid>
      </AuctionStatusBanner>
    </Link>
  );
};

export default CatalogueDetails;
