import { formatMessage } from "locale/components/locale-provider";
import { isEmpty, sortBy } from "lodash";

export const isNetworkConnectionWeak = () => {
  return navigator.connection.downlink < 1.5;
};

export const getStartOfToday = (date) => {
  const d = date ?? new Date();
  return new Date(d).setHours(0, 0, 0, 0);
};

export const groupBy = (xs, key) => {
  return xs.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const fixDecimals = (val, digits = 2) => {
  return (+val).toFixed(digits);
};

const checkDecimalAndFormat = (value) => {
  const decimalPart = value.toString().split(".")[1];
  const getFirstDecimal = decimalPart ? parseInt(decimalPart.charAt(0), 10) : 0;
  return getFirstDecimal === 0 ? value.toFixed(0) : value.toFixed(1);
};

export const formatNumber = (input) => {
  if (input >= 10000000) {
    return `${checkDecimalAndFormat(input / 10000000)} Cr`; // Convert to Crore
  }
  if (input >= 100000) {
    return `${checkDecimalAndFormat(input / 100000)} L`; // Convert to lakh
  }
  if (input >= 1000) {
    return `${checkDecimalAndFormat(input / 1000)} K`; // Convert to thousand
  }
  if (input >= 100) {
    return checkDecimalAndFormat(input); // Convert to hundred
  }
  if (input > 0) {
    return checkDecimalAndFormat(input); // Convert to hundred
  }
  if (input <= 0) {
    return 0;
  }
  return input.toString(); // No conversion needed
};

export const sortByKey = (arr, key) => {
  if (isEmpty(key) || isEmpty(arr) || !Array.isArray(arr)) return arr;
  return sortBy(arr, (o) => o[key]);
};

export const isLotSold = (lot) => {
  return (!!lot?.bidder_id && !!lot?.sold_price) || !!lot?.return_to_farmer;
};

export const scrollToView = (ref, behavior = "auto", inline = "start") => {
  if (ref.current) {
    ref.current.scrollIntoView({
      behavior,
      inline,
    });
  }
};

// it takes a 1D array as input and returns its sorted form in ascending order.
// the array can be a array of numbers, strings
export const sortArr = (arr = []) => {
  if (!Array.isArray(arr) || arr.length <= 1) return arr;

  return arr.sort((a, b) => {
    if (a.length < b.length || a < b) return -1;
    if (a.length > b.length || a > b) return 1;
    return 0;
  });
};

export const getListOfTodaysAuction = (auctionDocs) => {
  return auctionDocs.filter((auction) => {
    return getStartOfToday(auction?.name) === getStartOfToday();
  });
};

export const getAuctionerAssociatedAuction = (auctionDocs, me) => {
  return getListOfTodaysAuction(auctionDocs).filter((auction) =>
    me.mandiIds.includes(auction.mandi_id)
  );
};

export const getBidderAssociatedAuctions = (auctionDocs, me) => {
  return getListOfTodaysAuction(auctionDocs)?.filter((auction) =>
    me.mandiIds.includes(auction.mandi_id)
  );
};

// this functions rounds off any value to the precised number of value specified.
// round(100, 1) => 100     round(100.34, 1) => 100.3   round(100.788, 2) => 100.79
export const round = (value, precision) => {
  const multiplier = 10 ** (precision || 0);
  return Math.round(value * multiplier) / multiplier;
};

// function that converts a array of objects to a key value pair
// the key and val parametes in the func are the key : val pair that will be generated
// eg -> generateMap([{a : 1, b : 'Hello'}], 'a', 'b') -> {1 : 'Hello'}
export const generateMap = (data = [], key, val) => {
  let map = {};
  data.forEach((d) => {
    const k = d[key];
    const v = d[val];

    map = { ...map, ...{ [k]: v } };
  });

  return map;
};

export const removeFruitX = (name = "") => {
  return name.replace("FruitX", "").trim();
};

export const findWordInUrl = (url = "", word = "") => {
  const parts = url.split("/");
  return parts.includes(word);
};

export const convertToKebabCase = (originalString) => {
  return originalString.toLowerCase().replace(/\s+/g, "_");
};

export const formatProductCategories = (categories) => {
  const sortedCategories = categories.sort();

  const categoryCount = sortedCategories.length;
  const translateCategories = sortedCategories.map((item) => {
    return formatMessage(convertToKebabCase(item));
  });

  if (categoryCount <= 2) {
    return translateCategories.join(", ");
  }

  const firstTwoCategories = translateCategories.slice(0, 2).join(", ");
  const additionalCount = categoryCount - 2;
  return `${firstTwoCategories} +${additionalCount}`;
};

export const formatDecimalNumber = (values) => {
  const num = values.toFixed(2);
  if (!num) return { integer: "", decimal: "" };
  const [integer, decimal] = num.toString().split(".");
  return { integer, decimal: decimal ? `.${decimal}` : "" };
};
