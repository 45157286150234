import { createSlice } from "@reduxjs/toolkit";
import { firestore } from "utils/firebaseUtils/firebase";
import {
  subscribeToDoc,
  subscribeToDocs,
} from "utils/firebaseUtils/hooks/useFirestore";

const initialState = {
  auctions: [],
  currentAuction: null,
  currentAuctionLots: [],
  currentAuctionBiddings: [],
  selectedAuction: null,
  lotAvailableToBid: false,
  currentLot: null,
  selectedLot: null,
  auctionTokens: [],
  appAccessInfo: {},
};

const slice = createSlice({
  name: "auction",
  initialState,
  reducers: {
    setAuctions: (state, action) => ({
      ...state,
      auctions: action.payload.auctions,
    }),
    clearAuctions: (state) => ({
      ...state,
      auctions: [],
    }),
    setCurrentAuction: (state, action) => ({
      ...state,
      currentAuction: action.payload.auction,
    }),
    clearCurrentAuction: (state) => ({
      ...state,
      currentAuction: null,
    }),
    setCurrentLot: (state, action) => ({
      ...state,
      currentLot: action.payload.lot,
    }),
    clearCurrentLot: (state) => ({
      ...state,
      currentLot: null,
    }),
    setSelectedLot: (state, action) => ({
      ...state,
      selectedLot: action.payload.lot,
    }),
    clearSelectedLot: (state) => ({
      ...state,
      selectedLot: null,
    }),
    setCurrentAuctionLots: (state, action) => ({
      ...state,
      currentAuctionLots: action.payload.auctionLots,
    }),
    clearCurrentAuctionLots: (state) => ({
      ...state,
      currentAuctionLots: [],
    }),
    setCurrentAuctionBiddings: (state, action) => ({
      ...state,
      currentAuctionBiddings: action.payload.auctionBiddings,
    }),
    clearCurrentAuctionBiddings: (state) => ({
      ...state,
      currentAuctionBiddings: [],
    }),
    setSelectedAuction: (state, { payload }) => ({
      ...state,
      selectedAuction: payload.auction,
    }),
    isLotAvailableForBid: (state, { payload }) => ({
      ...state,
      lotAvailableToBid: payload,
    }),
    setAuctionTokens: (state, { payload }) => ({
      ...state,
      auctionTokens: payload.tokens,
    }),
    setAcccesInfo: (state, { payload }) => {
      return {
        ...state,
        appAccessInfo: payload.appAccessInfo,
      };
    },
    clearAllPreviousAuctionDetails: (state) => {
      return {
        ...state,
        currentLot: null,
        selectedLot: null,
        setCurrentAuction: null,
      };
    },
  },
});

export const recentOne = (documents) => {
  const sortedDocs = documents.sort((a, b) => {
    if (a.updated_at < b.updated_at) {
      return +1;
    }
    if (a.updated_at > b.updated_at) {
      return -1;
    }

    return 0;
  });

  return sortedDocs[0];
};

export const loadAuctions = () => (dispatch) => {
  const unsubscribe = subscribeToDocs(
    firestore.collection("auc_auctions"),
    (documents) => {
      return dispatch(
        slice.actions.setAuctions({
          auctions: documents,
        })
      );
    }
  );

  return () => {
    unsubscribe();
  };
};

export const loadAccessInfo = (uid) => (dispatch) => {
  const query = firestore
    .collection("users")
    .doc(uid)
    .collection("mandiAccess");

  const subscriber = (documents = []) => {
    let appAccessInfo = {};
    documents.forEach((d) => {
      const { access_level, mandi_id } = d || {};
      appAccessInfo = {
        ...appAccessInfo,
        [mandi_id]: access_level,
      };
    });

    return dispatch(slice.actions.setAcccesInfo({ appAccessInfo }));
  };

  return subscribeToDocs(query, subscriber);
};

export const subscribeToAuction = (auctionId, dispatch) => {
  return subscribeToDoc(
    firestore.collection("auc_auctions").doc(auctionId),
    (document) => {
      return dispatch(
        slice.actions.setCurrentAuction({
          auction: { ...document, id: auctionId },
        })
      );
    }
  );
};

export const subscribeToAuctionLots = (auctionId, dispatch) => {
  const lotsQuery = firestore
    .collection("auc_auctions")
    .doc(auctionId)
    .collection("auc_auction_lots")
    .where("is_deleted", "!=", true);

  return subscribeToDocs(lotsQuery, (documents) => {
    return dispatch(
      slice.actions.setCurrentAuctionLots({
        auctionLots: documents,
      })
    );
  });
};

export const subscribeToCurrentLot = (auctionId, dispatch) => {
  const currentLotQuery = firestore
    .collection("auc_auctions")
    .doc(auctionId)
    .collection("auc_auction_lots")
    .where("is_deleted", "!=", true)
    .where("is_current", "==", true);

  return subscribeToDocs(currentLotQuery, (documents) => {
    return dispatch(
      slice.actions.setCurrentLot({
        lot: documents[0],
      })
    );
  });
};

export const subscribeToSelectedLot = (auctionId, dispatch) => {
  const currentLotQuery = firestore
    .collection("auc_auctions")
    .doc(auctionId)
    .collection("auc_auction_lots")
    .where("is_deleted", "!=", true)
    .where("show_lot", "==", true);

  return subscribeToDocs(currentLotQuery, (documents) => {
    return dispatch(
      slice.actions.setSelectedLot({
        lot: recentOne(documents),
      })
    );
  });
};
export const subscribeToAuctionBiddings = (auctionId, dispatch) => {
  // scope it to auction.
  const biddingsQuery = firestore
    .collectionGroup("auc_auction_biddings")
    .where("auction_ref_id", "==", auctionId)
    .orderBy("created_at", "desc")
    .limit(50);

  return subscribeToDocs(biddingsQuery, (documents) => {
    return dispatch(
      slice.actions.setCurrentAuctionBiddings({
        auctionBiddings: documents,
      })
    );
  });
};

export const loadCurrentAuction =
  (auctionId, isBidder = false) =>
  (dispatch) => {
    if (!auctionId) return;
    const unsubscribeAuction = subscribeToAuction(auctionId, dispatch);

    let unsubscribeCurrentLot = () => {};
    let unsubscribeSelectedLot = () => {};
    let unsubscribeAuctionLots = () => {};

    if (isBidder) {
      unsubscribeCurrentLot = subscribeToCurrentLot(auctionId, dispatch);
      unsubscribeSelectedLot = subscribeToSelectedLot(auctionId, dispatch);
    } else {
      unsubscribeAuctionLots = subscribeToAuctionLots(auctionId, dispatch);
    }

    const unsubscribeAuctionBiddings = subscribeToAuctionBiddings(
      auctionId,
      dispatch
    );

    return () => {
      unsubscribeAuction();
      unsubscribeCurrentLot();
      unsubscribeSelectedLot();
      unsubscribeAuctionLots();
      unsubscribeAuctionBiddings();

      slice.actions.clearCurrentAuction(); // ideally should clear current lot and lots too.
      slice.actions.clearCurrentLot();
      slice.actions.clearSelectedLot();
      slice.actions.clearCurrentAuctionLots();
      slice.actions.clearCurrentAuctionBiddings();
    };
  };

export const loadTokens = (auctionId) => (dispatch) => {
  const linesQuery = firestore
    .collection("auc_auctions")
    .doc(auctionId)
    .collection("auc_auction_lines")
    .where("is_deleted", "==", false)
    .orderBy("sequence_order", "asc");

  subscribeToDocs(linesQuery, (documents) => {
    const auctionTokens = [];
    documents.forEach((aucLine) => {
      const { token_order = [] } = aucLine;
      for (let i = 0; i < token_order.length; i += 1) {
        const token = token_order[i];
        auctionTokens.push(token.split("-")[1]);
      }
    });
    dispatch(
      slice.actions.setAuctionTokens({
        tokens: auctionTokens,
      })
    );
  });
};

export const actions = {
  ...slice.actions,
  loadAuctions,
  loadCurrentAuction,
  loadTokens,
  loadAccessInfo,
};

export default slice.reducer;
