import { useEffect, useRef } from "react";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import { colors } from "styles/AtomicStyles";

const useStyles = makeStyles(() => ({
  input: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 0,
      height: "50px",
    },
  },
}));

const BidStepper = ({
  disabled = false,
  basePrice,
  setBasePrice,
  shouldInputBeFocused = false,
  dataInputId = "",
}) => {
  const classes = useStyles();
  const basePriceRef = useRef();
  const iconOpacity = disabled ? 0.3 : 1;
  const iconStyle = {
    opacity: iconOpacity,
    fontSize: 30,
    position: "relative",
    top: "10px",
  };

  useEffect(() => {
    if (
      basePriceRef.current &&
      shouldInputBeFocused &&
      document.activeElement !== basePriceRef.current
    ) {
      basePriceRef.current.focus();
    }
  }, [shouldInputBeFocused, document.activeElement, basePriceRef.current]);

  return (
    <Box
      component="form"
      className="flex-row justify-center align-items-center"
    >
      <RemoveCircleOutlineIcon
        htmlColor="white"
        sx={iconStyle}
        className="mr-3"
        onClick={() => +basePrice > 1 && setBasePrice(+basePrice - 1)}
      />
      <TextField
        type="number"
        inputRef={basePriceRef}
        sx={{
          width: "54px",
          height: "48px",
          background: colors.white,
          borderRadius: "8px",
        }}
        className={classes.input}
        value={basePrice || ""}
        onChange={(e) => setBasePrice(e.target.value)}
        inputProps={{ style: { fontSize: "14px" } }}
        dataid={dataInputId}
      />
      <ControlPointIcon
        htmlColor="white"
        sx={iconStyle}
        className="ml-3"
        onClick={() => setBasePrice(+basePrice + 1)}
      />
    </Box>
  );
};

export default BidStepper;
