import { useState } from "react";
import { currentMandiDetails } from "utils/localStorage";

const useMandiDetails = () => {
  const [mandiDetails, setMandiDetails] = useState({});

  const mandiInfo = (mandiId) => {
    const mandiData = currentMandiDetails(mandiId);
    setMandiDetails(mandiData || {});
  };

  return [mandiDetails, mandiInfo];
};

export default useMandiDetails;
