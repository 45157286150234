import Tooltip from "@mui/material/Tooltip";
import { withStyles } from "@mui/styles";

export const CustomTooltip = withStyles({
  tooltip: {
    borderRadius: "12px !important",
    marginTop: "4px !important",
    padding: "0 !important",
  },
})(Tooltip);
