import styled from "@emotion/styled";
import { Switch } from "@mui/material";

export const LargeSwitch = styled(Switch)(() => ({
  width: 60,
  height: 36,
  padding: 8,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "white",
      transform: "translateX(30px)",
    },
  },
  "& .MuiSwitch-thumb": {
    width: 28,
    height: 28,
  },
  "& .MuiSwitch-track": {
    borderRadius: 12,
  },
}));
