import { TextField } from "@mui/material";
import { makeStyles, styled } from "@mui/styles";

export const PhoneInputField = styled(TextField)(({ colors }) => ({
  background: colors.offGray,
  width: "100%",
  "& :focus": {
    boxShadow: "none !important",
  },
}));

export const classes = makeStyles(() => ({
  input: {
    fontSize: 22,
    fontWeight: 700,
    padding: "0.6rem !important",
  },
  tickImage: {
    height: "2rem",
    width: "3rem",
  },
  label: {
    display: "flex",
    fontWeight: "400 !important",
    marginBottom: "0.375rem",
  },
}));
