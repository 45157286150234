import { settings } from "utils/const";

export const getCurrentLot = (auction) => {
  return auction.currentAuctionLots.find((l) => l.is_current);
};

export const getCurrentLotForTv = (auction) => {
  const lotObj = auction.currentAuctionLots.find(
    (lot) => lot.is_current || lot.show_lot
  );

  if (!lotObj) return null;

  const biddings = getBiddingsByLot(lotObj, auction.currentAuctionBiddings);
  const maxBid = getMaxBid(lotObj, biddings);
  const remTime = getRemainingTime(lotObj, maxBid);

  const curLotObj = {
    ...lotObj,
    biddings,
    maxBid,
    computedRemainingTime: remTime,
    hasTimeLeft: remTime > 0,
  };

  return curLotObj;
};
export const getCurrentLotForBidder = (auction) => {
  const lotObj = auction.currentLot || auction.selectedLot;

  if (!lotObj) return null;

  const biddings = getBiddingsByLot(lotObj, auction.currentAuctionBiddings);
  const maxBid = getMaxBid(lotObj, biddings);
  const remTime = getRemainingTime(lotObj, maxBid);

  const curLotObj = {
    ...lotObj,
    biddings,
    maxBid,
    computedRemainingTime: remTime,
    hasTimeLeft: remTime > 0,
  };

  return curLotObj;
};

export const getLotById = (auction, id) => {
  if (!id) return null;
  return auction.currentAuctionLots.find((l) => l.id === id);
};

export const getCurrentLotMaxBidding = (auction) => {
  const currentLot = getCurrentLot(auction);
  const biddings = getBiddingsByLot(currentLot, auction.currentAuctionBiddings);
  const maxBid = getMaxBid(currentLot, biddings);

  return maxBid;
};

export const getValidBiddings = (lot, biddings) => {
  if (!lot) return [];

  if (lot.return_to_farmer === true) return [];

  if (lot.ended_by_timeout === true && lot.max_bid_ref_id === null) {
    return [];
  }

  const allBiddings = [...biddings]; // create empty array even if its null

  const filteredBiddings = allBiddings.filter((bidding) => {
    if (!bidding.acknowledged)
      // Bg timer should acknowledge the bidding first.
      return false;

    if (lot.max_bid_ref_id) {
      if (lot.max_bid_placed_at > bidding.created_at) return false;
    }
    if (bidding.created_at < lot.auction_started_at) return false;

    return true;
  });

  return filteredBiddings;
  // return allBiddings;
};
export const getMaxBid = (lot, biddings) => {
  const validBiddings = getValidBiddings(lot, biddings);
  const sortedBiddings = validBiddings.sort((a, b) => {
    if (a.price < b.price) {
      return +1;
    }
    if (a.price > b.price) {
      return -1;
    }

    if (a.created_at < b.created_at) {
      return +1;
    }
    if (a.created_at > b.created_at) {
      return -1;
    }

    return 0;
  });

  return sortedBiddings[0];
};

export const getRemainingTime = (lotObj, maxBid) => {
  if (!lotObj) return 0;

  let maxBidRemainingTime = 0;
  let lotRemainingTime = 0;
  let diffTime = 0;

  lotRemainingTime = lotObj.updated_at - lotObj.auction_started_at;

  if (maxBid) {
    maxBidRemainingTime = maxBid.updated_at - maxBid.created_at;

    diffTime =
      settings.elapseWindow - Math.min(maxBidRemainingTime, lotRemainingTime);
  } else {
    diffTime = settings.elapseWindow - lotRemainingTime;
  }

  diffTime -= settings.latencyCompensation // 200ms buffer. Show lesser time left for the bidder than the actual time left.

  const diffTimePositive = diffTime < 0 ? 0 : diffTime;

  return diffTimePositive;
};

export const getBiddingsByLot = (lot, auctionBiddings) => {
  if (!lot) return [];

  return auctionBiddings.filter((bidding) => {
    if (bidding.lot_id === lot.lot_id) {
      return true;
    }
    return false;
  });
};
