import styled from "@emotion/styled";
import { makeStyles } from "@mui/styles";

import { ImageIcons } from "components";

export const ProductImage = styled(ImageIcons)`
  height: 66px;
  width: 66px;
`;

export const styles = makeStyles(({ colors }) => ({
  emptyDiv: {
    marginBottom: "1.25rem",
    height: "0.625rem",
    background: `${colors.lightGray} !important`,
  },
  lotInfoWrapper: {
    display: "flex",
    justifyContent: "space-around",
    background: `${colors.lightGreen} !important`,
    padding: "0.75rem 0",
    margin: "0.75rem 0 1.25rem 0",
    borderRadius: "0.75rem",
  },
}));
