import { useEffect, useState } from "react";
import { settings } from "utils/const";
import { dbFieldValue, updateDocument } from "utils/firebaseUtils/api";
import { endAuction } from "utils/networkUtils";

const AuctionTimerCalculator = ({ lot }) => {
  const [reloadedLot, setReloadedLot] = useState(lot);

  const triggerPulse = async () => {
    if (reloadedLot.is_current === false) {
      return;
    }
    if (reloadedLot.maxBid && reloadedLot.maxBid.updated_at) {
      updateDocument(reloadedLot.maxBid.doc);
    }

    updateDocument(reloadedLot.doc);
  };

  const hasLotElapsed = () => {
    const diff = reloadedLot.updated_at - reloadedLot.auction_started_at;
    // console.log(reloadedLot.updated_at, reloadedLot.auction_started_at, diff, 'diff lot...')
    return diff >= settings.elapseWindow;
  };

  const hasBidElapsed = () => {
    const diff = reloadedLot.maxBid.updated_at - reloadedLot.maxBid.created_at;
    // console.log(reloadedLot.maxBid.updated_at, reloadedLot.maxBid.created_at, diff, 'diff maxbid...')

    return diff >= settings.elapseWindow;
  };

  const endAuctionIfElapsed = async () => {
    if (!reloadedLot) {
      return false;
    }

    if (!hasLotElapsed()) {
      return false;
    }

    if (reloadedLot.maxBid && !hasBidElapsed()) {
      return false;
    }

    const ended_by_timeout = true;
    const timeout_that_time = settings.elapseWindow;

    if (reloadedLot.maxBid && hasBidElapsed()) {
      const max_bid_ref_id = reloadedLot.maxBid.id;
      const max_bid_placed_at = reloadedLot.maxBid.created_at;
      endAuction(
        reloadedLot,
        reloadedLot.maxBid.price,
        reloadedLot.maxBid.bidder_ref_id,
        reloadedLot.maxBid.short_code,
        reloadedLot.maxBid.bidder_name ?? "",
        true,
        ended_by_timeout,
        timeout_that_time,
        max_bid_ref_id,
        max_bid_placed_at
      );
      return true;
    }

    if (hasLotElapsed()) {
      updateDocument(reloadedLot.doc, {
        is_current: false,
        sold_price: null,
        bidder_id: null,
        short_code: null,
        bidder_name: null,
        max_bid_ref_id: null,
        max_bid_placed_at: null,
        auction_ended_at: dbFieldValue.serverTimestamp(),
        ended_by_timeout,
        timeout_that_time,
      });
      return true;
    }

    return true;
  };

  useEffect(() => {
    if(true)
      return false; // temporarily disable timer in the Autioneer Browser
      
    // this happens due to some fire-base internal optimization.
    if (lot?.updated_at === null) {
      return () => {};
    }

    setReloadedLot(lot);
    endAuctionIfElapsed();

    const interval = setInterval(() => {
      triggerPulse();
    }, 250); // configure?

    return () => {
      clearInterval(interval);
    };
  }, [lot]);

  return <></>;
};

AuctionTimerCalculator.propTypes = {};
AuctionTimerCalculator.defaultProps = {};

export default AuctionTimerCalculator;
