import { makeStyles } from "@mui/styles";

export const classes = makeStyles(() => ({
  aceeptButton: {
    textTransform: "lowercase !important",
  },
  iframeStyle: {
    width: "100%",
    height: "100%",
    border: "none",
    position: "relative",
    top: "-1rem",
  },
}));

export const dialogStyle = {
  height: "70%",
  position: "relative",
};
