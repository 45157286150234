import { mandiService } from "./base";

class Mandi {
  getMandiLists = () => {
    return mandiService.get("mandi");
  };

  getGradeAndRangeInfo = () => {
    return mandiService.get("gradeSizeFilters");
  };

  pushData = (auction_id) => {
    // mandiService.post(`backwardsync?auction_id=${auction_id}`);
  };

  getAuctionLotImages = async (auction_lot_ids) => {
    const response = await mandiService.get("getqualityimages", {
      params: {
        auction_lot_ids,
      },
    });
    const LotImages = [];
    Object.values(response.responseData).map((image) => {
      return image.forEach((item) => {
        LotImages.push({
          acknowledgement_slip: item.acknowledgement_slip,
          id: item.id,
        });
      });
    });
    return LotImages;
  };
}

export default new Mandi();
