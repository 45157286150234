import { FormControlLabel, FormGroup, Switch } from "@mui/material";
import { colors } from "styles/AtomicStyles";

import { LargeSwitch } from "./styled";

const switchStyle = {
  "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
    background: colors.primary,
    opacity: 1,
  },
  "& .MuiSwitch-thumb": {
    background: colors.white,
  },
};

const CustomSwitch = ({
  disabled = false,
  label,
  size = "medium",
  toggleSwitch = () => {},
  isSwitchOn = false,
  dataid = "",
  formGroupWidth = "",
}) => {
  const VeAuctionSwitch = size === "large" ? LargeSwitch : Switch;

  return (
    <FormGroup sx={{ width: formGroupWidth }}>
      <FormControlLabel
        label={label}
        control={
          <VeAuctionSwitch
            checked={isSwitchOn}
            onChange={toggleSwitch}
            inputProps={{ "aria-label": "controlled" }}
            disabled={disabled}
            sx={switchStyle}
            dataid={dataid}
            data-cy="home.auction.start"
          />
        }
      />
    </FormGroup>
  );
};

export default CustomSwitch;
