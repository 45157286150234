import { useRef, useState } from "react";
import { settings } from "utils/const";

import { Textarea } from "./styled";

const TextArea = ({
  minRows = 5,
  placeholder = "Remark",
  handleTextAreaValueChange = () => {},
  data,
}) => {
  const [textAreaInput, setTextAreaInput] = useState(data);
  const inputRef = useRef();
  const handleEnteredText = (e) => {
    setTextAreaInput(e.target.value);
    if (inputRef.current) {
      clearTimeout(inputRef.current);
    }
    inputRef.current = setTimeout(() => {
      handleTextAreaValueChange(e.target.value);
    }, settings.debouncingTime * 1000);
  };
  return (
    <Textarea
      minRows={minRows}
      placeholder={placeholder}
      value={textAreaInput}
      onChange={handleEnteredText}
    />
  );
};

export default TextArea;
