import styled from "@emotion/styled";

export const NetWorkWrapper = styled.div(({ showText, colors }) => ({
  gap: "0.5rem",
  display: "flex",
  padding: "0.75rem",
  background: colors.lightSilver,
  alignItems: "center",
  borderRadius: "12px",
  justifyContent: "center",
  minWidth: showText === "true" ? "108px" : "",
}));
