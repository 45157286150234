import styled from "@emotion/styled";
import { createTheme } from "@mui/material";

export const colors = {
  primary: "#2C7973",
  white: "#fff",
  lightGreen: "#FFFFFF54",
  black: "#000000",
  offWhite: "#ffffffd9",
  gray: "#0000008a",
  lightBlack: "#000000d6",
  lightGreen1: "#e6eded",
  lightRed: "#F15E5E",
  fadeGrey: "#6C6C6C",
  lightGray: "#F9F9F9",
  darkGreen: "#034D47",
  violet: "#5200ffd6",
  red: "#db2a2a",
  yellow: "#F7CB45",
  offGray: "#EEEEEE",
  darkGray: "#BFBFBF",
  fadedBlack: "#5E5858",
  disabled: "#D9D9D9",
  smokeWhite: "#F7F7F7",
  zambezi: "#5E5A5A",
  fadedGray: "#034d471a",
  darkSilver: "#00000040",
  silver: "00000014",
  lightIvory: "#0000000d",
  duskyGray: "#00000026",
  cream: "#f2f2f28a",
  blackSesame: "#000000cc",
  partialWhite: "#ffffffde",
  mud: "#0000001f",
  purple: "#D1F1EE",
  lightPurple: "#FAFAFA",
  grey: "#E2E2E2",
  lightGrey: "#FAFAFA",
  paleSeaGreen: "#034d4721",
  fadedGreen: "#e6eded",
  blueGreen: "#e0e9e9b8",
  seaGreen: "#72beb83d",
  lightSilver: "#F3F3F3",
  mustardYellow: "#ffc900",
  darkPeach: "#FF6164",
  ashGreen: "#35716C",
  ashGray: "#EBEBEB",
  smokySilver: "#F8F8F8",
  cement: "#C4C4C4",
  gray2: "#EEEEEE",
  gray3: "#7E7E7E",
  darkAsh: "#5E5858",
  darkGray2: "#555454",
  babyPink: "#FFE6E6",
  green: "#1C9D40",
  green2: "#29C655",
  successGreen: "#269759",
  offWhite2: "#ffffff00",
  tintsOfWhisper: "#eeeeee",
  lightYellow: "#FFE687",
  mustardYellow2: "#F9C400",
  mustardYellow3: "#EAB807",
  disabled1: "#A5A5A5",
  seaGreen1: "#E2F2F0",
  lightGreen2: "#D3FFE0",
  orange: "#e07728",
  orangeYellow: "#FFE176",
  lightBrown: "#F1DDBC",
  leafGreen: "#E5EFA0",
  yellowWhite: "#F9F0D1",
  gravel: "#484848",
  lightYellow1: "#F7C200",
  lightGoldenYellow: "#ffc90024",
  mudGray: "#5B5B5B",
  lightGold: "#faf2eb",
  darkGold: "#daa520",
  lightGoldOrange: "#FBECE0",
  darkGoldOrange: "#D28145",
  lightBlue: "#EEF4F4",
  grayText: "#616161",
  secondaryLightBlue: "#B4D8D5",
  secondaryLightCream: "#F4F4F4",
  secondaryLightGrey: "#403F42",
  blueGradient: "#FBFBFB",
  whiteShadow: "#E1E1E1",
  greyTextColor: "#4B4B4B",
  lightPink: "#FFEBEE",
  lightgrey: "#F5F5F5",
  blackShadow: "#00000040",
};

export const fonts = {
  NOTO_SANS: "Noto Sans",
  ROBOTO: "Roboto",
};

export default {
  colors,
  fonts,
};

export const theme = createTheme({
  colors,
});

export const PageWrapper = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  flexGrow: "1",
  height: "100%",
}));
