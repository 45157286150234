import React, { useEffect, useState } from "react";

const LocalTimer = ({ children, timeOnLoad, seconds }) => {
  const [timeTicker, setTimeTicker] = useState(seconds);

  const diffInMS = () => {
    return Date.now() - timeOnLoad;
  };

  useEffect(() => {
    setTimeTicker(seconds);

    // const interval = setInterval(() => {
    //   const diffInS = diffInMS() / 1000.0;
    //   if (seconds - diffInS > 0) setTimeTicker(seconds);
    //   else setTimeTicker(0);
    // }, 10000 / 60); // configure?

    // return () => {
    //   clearInterval(interval);
    // };
  }, [seconds]);

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { seconds: timeTicker });
    }
    return child;
  });

  return <>{childrenWithProps}</>;
};

export default LocalTimer;
