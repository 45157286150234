import { networkStrengthParams } from "utils/const";
import { firestore } from "utils/firebaseUtils/firebase";

import { Ping } from "../model/Ping";
import { PingsHistory } from "../model/PingsHistory";
import { persistLatency } from "../repo/firebaseRepo";
import { subscribeToPingResponses } from "../susbscribers/pingsSubscriber";
import { getCurrTime } from "../utils/date";

export const LatencyCalculator = class {
  pingsHistory = null;

  sessionId = null;

  userId = null;

  pingId = 0;

  latencyListener = null;

  timerIntervalHandle = null;

  pingResponsesUnsubscribeHandler = null;

  isActive = true; // if false  => stop pinging

  constructor(userId, sessionId, latencyListener) {
    this.pingsHistory = new PingsHistory();
    this.sessionId = sessionId;
    this.userId = userId;
    this.latencyListener = latencyListener;

    this.pingResponsesUnsubscribeHandler = subscribeToPingResponses(
      this.userId,
      this.sessionId,
      this.handlePingsReceived
    );
  }

  startPinging = () => {
    const _this_ = this;
    this.timerIntervalHandle = setInterval(() => {
      if (_this_.isActive) _this_.handlePulse();
    }, networkStrengthParams.pingIntervel);
  };

  pausePinging = () => {
    // console.log('pausing pinging..')
    this.isActive = false;
  };

  resumePinging = () => {
    // console.log('resuming pinging..')
    this.isActive = true;
  };

  stopPinging = () => {
    clearInterval(this.timerIntervalHandle);
    this.pingResponsesUnsubscribeHandler();
  };

  handlePulse = () => {
    this.performPing();

    const latency = this.areEnoughPingsAcknowledged()
      ? this.pingsHistory.computeLatency()
      : getCurrTime() - this.getLatestAckTime();
    this.latencyListener(latency);
    this.logLatency(latency);
  };

  performPing = () => {
    const initiatedAt = getCurrTime();

    const ping = new Ping(initiatedAt);

    ping.pingEndpoint(
      this.userId,
      this.sessionId,
      this.getPingId(),
      this.initiatedAt
    );

    this.pingsHistory.addPing(ping);
  };

  // For analytical purposes, store the latency histroy in the database over time.
  logLatency = (latency) => {
    persistLatency(this.userId, latency);
  };

  handlePingsReceived = (pingDocuments) => {
    this.pingsHistory.handlePingsReceived(pingDocuments);
  };

  getPingId = () => {
    const { pingId } = this;
    this.pingId = (pingId + 1) % networkStrengthParams.numDocsPerSession;
    return pingId;
  };

  areEnoughPingsAcknowledged = () => {
    return this.pingsHistory.areEnoughPingsAcknowledged();
  };

  getLatestAckTime = () => this.pingsHistory.getLatestAckTime();
};
