const formatter = new Intl.NumberFormat("en-in");

export function currency(
  data = 0,
  color,
  rupeesIcon = true,
  wrapOperator = true,
  restClass
) {
  const finalData = formatter.format(Math.abs(data).toFixed(2));
  const finalValue = rupeesIcon ? `₹${finalData}` : finalData;

  if (data < 0) {
    const negOutstanding = wrapOperator
      ? `(-) ${finalValue}`
      : `-${finalValue}`;
    return <b>{negOutstanding}</b>;
  }
  return <b className={color ? "text-[#CC1111]" : restClass}>{finalValue}</b>;
}
