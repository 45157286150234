import { FieldValue, firestore } from "./firebase";

export const db = firestore;
export const dbFieldValue = FieldValue;

export const updateDocByPath = (path, docId, attributes) => {
  db.collection(path)
    .doc(docId)
    .set(
      {
        updated_at: dbFieldValue.serverTimestamp(),
        ...attributes,
      },
      { merge: true }
    );
};

export const updateDocument = (doc, attributes) =>
  doc.ref.set(
    {
      updated_at: dbFieldValue.serverTimestamp(),
      ...attributes,
    },
    { merge: true }
  );

export const addDocument = (collection, attributes) =>
  collection.add({
    updated_at: dbFieldValue.serverTimestamp(),
    created_at: dbFieldValue.serverTimestamp(),
    ...attributes,
  });

export const addDocumentWithCustomId = (collection, customDocId, attributes) =>
  collection.doc(customDocId).set({
    ...attributes,
  });

export const collectionGroup = (cg) => db.collectionGroup(cg);

export const withinTransaction = async (block) => {
  try {
    // await block()
    await db.runTransaction(block());
  } catch (e) {
    console.log("Transaction failure:", e);
  }
};
