import styled from "@emotion/styled";
import { makeStyles } from "@mui/styles";

import { ImageIcons } from "components";

export const ProductImage = styled(ImageIcons)`
  height: 20px;
  width: 20px;
`;

export const useStyles = makeStyles(({ colors }) => ({
  headerMargin: {
    margin: "1.5rem 2rem",
  },
  fixHeader: {
    position: "sticky",
    top: 0,
    background: "#fff",
    zIndex: "999",
  },
  displayFlex: {
    display: "flex",
  },
  marginOnlineIndicator: {
    margin: "auto ",
    marginRight: "5px",
  },

  backButton: {
    color: "black",
  },
  mainContainer: {
    gap: "4",
    height: "80vh",
  },
  boxContainer: {
    border: "1px solid #ccc",
    borderRadius: "5px",
    padding: "1rem",
    marginBottom: "1rem",
    display: "flex",
    justifyContent: "space-between",
  },
  button: {
    margin: "2rem 0",
    width: "100%",
    background: colors.primary,
    fontSize: 16,
  },
  imageIcon: {
    height: "20px",
    width: "45px",
    margin: "auto",
    marginLeft: "5px",
  },
  selectedBox: {
    backgroundColor: colors.paleSeaGreen,
    borderColor: colors.primary,
  },
  selectedRadio: {
    color: `${colors.primary} !important`,
  },
  alignCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  verticalAlign: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  alignEnd: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
  },
  successBackground: {
    background: colors.successGreen,
    color: "white",
  },
  pendingBackground: {
    background: "#fff",
  },
  paymentContainer: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  paymentIcon: {
    height: 30,
  },
  paymentSuccess: { height: 150, marginBottom: "2rem", fontSize: "12rem" },
  textFieldInputStyle: {
    padding: "0.7rem",
    fontSize: "1.4rem",
    background: "#fff",
  },
  spaceBetween: {
    display: "flex",
    justifyContent: "space-between",
  },
  marginLeft: {
    marginLeft: "1rem",
  },
  marginRight: {
    marginRight: "1rem",
  },
  formControlPadding: {
    padding: "1rem 0",
  },
  otherUPIsOption: {
    marginTop: "2rem",
    textDecoration: "underline",
  },
  bankCard: {
    border: "1px lightGrey solid",
    padding: "1rem",
    background: "#EEF2F8",
  },
  arrowIconStyle: {
    marginLeft: "0.5rem",
    color: "lightgray",
  },
  textInputStyle: {
    width: "14rem",
    margin: 0,
  },
  centerText: {
    textAlign: "center",
  },
  bankIcon: {
    height: "25px",
    paddingRight: "1rem",
  },
  currencyColor: {
    color: colors.primary,
  },
  failedColor: {
    color: "red",
  },
  outerCircle: {
    width: "140px",
    height: "140px",
  },
  innerCircle: { width: "130px", height: "130px" },
  doneIcon: {
    color: "white",
    fontSize: "100px",
  },
}));
