import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Card,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { translate } from "locale/formatter";
import { getBankAccountDetails } from "services/paynow";
import { currency } from "utils/currencyFormatter";
import { getPaymentLink, PAYMENT_MODE } from "utils/payNow";

import { ImageIcons } from "components";

import { useStyles } from "./styled";

const PaymentWindow = ({
  paymentMode,
  paymentMethod,
  setPaymentMethod,
  payableAmount,
  customerData,
}) => {
  const [bankDetails, setBankDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const { me } = useSelector((state) => state.app);

  const {
    selectedRadio,
    formControlPadding,
    paymentIcon,
    spaceBetween,
    marginLeft,
    otherUPIsOption,
    bankCard,
    displayFlex,
    bankIcon,
    currencyColor,
  } = useStyles();

  const { BANK, UPI } = PAYMENT_MODE;

  const handlePaymentChange = (event) => {
    setPaymentMethod(event.target.value);
  };

  const getBankDetails = () => {
    setLoading(true);

    getBankAccountDetails(me.customerId)
      .then((res) => {
        const bankDetails = [
          { label: "Bank name", value: res.bank_name },
          { label: "IFSC Code", value: res.ifsc_code },
          { label: "AC no.", value: res.account_number },
          { label: "AC Name", value: res.account_name },
        ];
        setBankDetails(bankDetails);
      })
      .catch(() => {
        const bankDetails = [
          { label: "Bank name", value: "" },
          { label: "IFSC Code", value: "" },
          { label: "AC no.", value: "" },
          { label: "AC Name", value: "" },
        ];
        setBankDetails(bankDetails);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (paymentMode === BANK) {
      getBankDetails();
    }
  }, [paymentMode]);

  const handleOtherUPIPayment = () => {
    window.open(getPaymentLink("base", customerData, +payableAmount));
  };

  const paymentOptions = [
    {
      value: "gpay",
      label: "GooglePay",
      icon: "IMG_GPAY",
      className: `${paymentIcon} ${marginLeft}`,
    },
    {
      value: "phonepe",
      label: "PhonePay",
      icon: "IMG_PHONEPE",
      className: paymentIcon,
    },
    {
      value: "paytm",
      label: "PaytmPay",
      icon: "IMG_PAYTM",
      className: `${paymentIcon} ${marginLeft}`,
    },
  ];

  return paymentMode === UPI ? (
    <div>
      <Box mt={3} className={spaceBetween}>
        <Typography variant="h4" gutterBottom>
          {translate("amount_payable")}
        </Typography>
        <Typography variant="h4">
          <b>{currency(payableAmount)}</b>
        </Typography>
      </Box>

      <Box m={1} mt={3}>
        <Typography variant="h5" gutterBottom>
          {translate("pay_via")}
        </Typography>
        <RadioGroup value={paymentMethod} onChange={handlePaymentChange}>
          {paymentOptions.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              control={<Radio className={selectedRadio} />}
              label={
                <ImageIcons name={option.icon} className={option.className} />
              }
              className={formControlPadding}
            />
          ))}
          <Typography
            variant="h5"
            className={otherUPIsOption}
            onClick={handleOtherUPIPayment}
          >
            {translate("otherUPIs")}
          </Typography>
        </RadioGroup>
      </Box>
    </div>
  ) : (
    <div>
      <div className={displayFlex}>
        <ImageIcons name="IMG_BANK_COLOR" className={bankIcon} />
        <Typography variant="h4" gutterBottom>
          {translate("internet_banking")}
        </Typography>
      </div>

      <Card elevation={0} className={bankCard}>
        <Typography variant="h6">
          {translate("please_transfer_the")}{" "}
          <span className={currencyColor}>{currency(payableAmount)}</span> to
          {translate("to_below_bank_account")}
        </Typography>
        {!loading && (
          <Grid container display="flex" className={formControlPadding}>
            {bankDetails.map((item, index) => (
              <Grid item xs={6} sm={6} key={index}>
                <div className="mb-[1rem]">
                  <Typography variant="h6" color="gray">
                    {item.label}
                  </Typography>
                  <Typography variant="h5">
                    <b>{item.value}</b>
                  </Typography>
                </div>
              </Grid>
            ))}
          </Grid>
        )}
      </Card>
    </div>
  );
};

export default PaymentWindow;
