export const URL_PREFIX = {
  base: "upi://pay",
  gpay: "tez://upi/pay",
  phonepe: "phonepe://pay",
  paytm: "paytmmp://pay",
};

export const getPaymentLink = (mode, userData, amount) => {
  const params = {
    pa: userData.vpas[0],
    pn: userData.merchant_name,
    mc: userData.merchant_category,
    am: amount,
    cu: userData.currency,
  };

  const searchParams = new URLSearchParams(params);

  return `${URL_PREFIX[mode]}?${searchParams.toString()}`;
};

export const CONSTANT_REQUEST_DATA = {
  INITIATOR_TYPE: "MandiCreditUnit",
  BRAND: "FruitX",
  GENERATED_VIA: "MANDI_PAYMENT",
};

export const PAYMENT_STATUS = {
  PENDING: "pending",
  SUCCESS: "success",
  FAILED: "failed",
};

export const PAYMENT_OPTION = {
  ENTER_AMOUNT: "enterAmount",
  OVERDUE: "overdue",
  OUTSTANDING: "outstanding",
};

export const PAYMENT_MODE = {
  UPI: "upi",
  BANK: "bank",
};
export const PAYMENT_METHOD = {
  GPAY: {
    value: "gpay",
    label: "GooglePay",
  },
  PHONEPE: { value: "phonepe", label: "PhonePay" },
  PAYTM: { value: "paytm", label: "PaytmPay" },
};

export const NUMBER_OF_ATTEMPTS = 12;

export const VISIBILITY_STATE = "visible";
export const VISIBILITY_EVENT = "visibilitychange";
