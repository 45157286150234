import { addDocument, addDocumentWithCustomId } from "utils/firebaseUtils/api";
import { FieldValue, firestore } from "utils/firebaseUtils/firebase";

export const persistLatency = (userId, latency) => {
  const data = {
    latency,
  };
  const query = firestore
    .collection("users")
    .doc(userId)
    .collection("latencies");
  addDocument(query, data);
};
export const savePing = (userId, sessionId, pingId, initiatedAt) => {
  const data = {
    initiatedAt,
    updated_at: FieldValue.serverTimestamp(),
    sessionId,
  };
  const query = firestore.collection("users").doc(userId).collection("pings");
  addDocumentWithCustomId(query, `${sessionId}_${pingId}}`, data);
};
