import { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { path } from "utils/const";

import AuctioneerTimerPage from "./timer";

const Auth = lazy(() => import("pages/auth"));

const ReadOnlyCatalogue = lazy(() => import("pages/catalogue-readOnly"));
const AuctioneerDashboard = lazy(() => import("pages/auctioneer/dashboard"));
const AuctioneerPlatform = lazy(() => import("pages/auctioneer/platform"));
const LotListingPage = lazy(() => import("pages/catalogue/lotListingPage"));

const AuctionRoutes = () => {
  return (
    <>
      <Routes>
        {/* Ability to access login page despite lready logged in. Required for Cypress to switch users during testing.. */}
        {/* <Route path="/login" element={<Auth />} /> */}
        <Route
          element={<AuctioneerDashboard />}
          exact
          path={path.auctioneerDashboard}
        />
        <Route
          element={<AuctioneerPlatform />}
          exact
          path={path.auctioneerPlatform}
        />
        <Route
          element={<AuctioneerTimerPage />}
          exact
          path={path.auctioneerTimerPage}
        />
        <Route
          element={<ReadOnlyCatalogue />}
          exact
          path={path.catalogueReadOnly}
        />
        <Route
          element={<LotListingPage />}
          exact
          path={path.lotListingReadOnly}
        />
        <Route path="*" element={<Navigate to={path.auctioneerDashboard} />} />
      </Routes>
    </>
  );
};

export default AuctionRoutes;
