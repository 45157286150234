import { makeStyles } from "@mui/styles";

export const styles = makeStyles(({ colors }) => ({
  inputLabel: {
    fontWeight: 400,
    fontSize: "1.3rem",
    marginBottom: "0.6rem",
  },
  inputContainer: {
    position: "relative",
    marginBottom: "1.7rem",
  },
  input: {
    fontSize: "1rem",
    "&::placeholder": {
      textOverflow: "ellipsis !important",
      fontSize: "0.875rem",
    },
  },
  inputField: ({ inputStyles: { padding, fontSize, background } = {} }) => ({
    padding: padding || "1rem !important",
    fontSize: fontSize || "1.4rem !important",
    background: background || colors.offGray,
  }),
  eyeIcon: {
    height: "1.3rem",
    width: "1.6rem",
    position: "absolute",
    top: "36%",
    right: "4%",
    cursor: "pointer",
  },
}));
