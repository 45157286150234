export const LOGIN_EVENTS = {
  LOGIN_FORM_SHOWN: "LoginFormShown",
  LOGIN_PAGE_ACTION: "LoginPageAction",
  OTP_PAGE_ACTION: "OtpPageAction",
  OTP_ENTERED: "OptEntered",
  LOGIN_SUCCESSFUL: "LoginSuccessful",
};

export const HOME_SCREEN_EVENTS = {
  HOME_SCREEN_VIEWED: "HomeScreenViewed",
  HOME_BUTTON_CLICK: "HomeButtonClick",
  MY_BOOKS_BUTTON_CLICK: "MyBooksButtonClick",
  PROFILE_ICON_CLICK: "ProfileIconClick",
  LOGOUT_CLICK: "LogoutClick",
  MY_TRANSACTION_ICON_CLICK: "MyTransactionsIconClick",
  AUCTION_CARD_VIEWED: "AuctionCardViewed",
  CATALOG_BUTTON_CLICK: "CatalogButtonClick",
  ENTER_AUCTION_BUTTON_CLICK: "EnterAuctionButtonClick",
  MY_BUYS_VIEW_ALL_CLICK: "MyBuysViewAllClick",
  MY_BUYS_HORIZONTAL_SCROLL: "MyBuysHorizontalScroll",
  HOME_VERTICAL_SCROLL: "HomeVerticalScroll",
  NETWORK_STRENGTH_CLICK: "NetworkStrengthClick",
};

export const MY_BUYS_CARD_DETAILS_PAGE_EVENTS = {
  MY_BUYS_CARD_DETAILS_VIEWED: "MyBuysCardDetailsViewed",
  MY_BUYS_CARD_DETAILS_PAGE_SCROLL: "MyBuysCardDetailsPageScroll",
  DOWNLOAD_INVOICE: "DownloadInvoice",
};

export const MY_TRANSACTION_PAGE_EVENTS = {
  MY_TRANSACTION_PAGE_VIEWED: "MyTransactionPageViewed",
};

export const MY_BUYS_LISTING_PAGE_EVENTS = {
  MY_BUYS_LISTING_VIEWED: "MyBuysListingViewed",
  MY_BUYS_LISTING_DATE_FILTER_CLICK: "MyBuysListingDateFilterClick",
  MY_BUYS_LISTING_DATE_FILTER_APPLY: "MyBuysListingDateFilterApply",
};

export const MY_PAYMENTS_LISTING_PAGE_EVENTS = {
  MY_PAYMENTS_LISTING_VIEWED: "MyPaymentsListingViewed",
  MY_PAYMENTS_VALUE_HYPER_LINK_CLICK: "MyPaymentsValueHyperlinkClick",
};

export const MY_BOOKS_EVENTS = {
  MANDI_BOOKS_CARD_VIEWED: "MandiBooksCardViewed",
  MY_BOOKS_VERTICAL_SCROLL: "MyBooksVerticalScroll",
  PAY_NOW_BUTTON_CLICK: "PayNowButtonClick",
};

export const PAGE_EVENTS = {
  MANDI_FILTERED: "MandiFilteredPage",
  MY_TRANSACTION: "MyTransactionsPage",
  HOME: "FromHomePage",
};
