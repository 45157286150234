import { useNavigate } from "react-router-dom";
import { translate } from "locale/formatter";
import { removeFruitX } from "utils";

import Text from "components/Text";

import {
  classes,
  HeaderWrapper,
  ProductImage,
  TransactionHeader,
} from "./styled";

const Header = ({ title = "", isMandiActive = false, mandiName = "" }) => {
  const { fontNoto } = classes();
  const navigate = useNavigate();
  return (
    <HeaderWrapper>
      <TransactionHeader>
        <ProductImage
          name="backBtn"
          alt="backArrow"
          onClick={() => navigate(-1)}
        />
        <div
          className="flex items-center"
          style={
            isMandiActive ? { marginRight: "3rem" } : { marginRight: "2rem" }
          }
        >
          {mandiName ? (
            <>
              <Text className={fontNoto}>{removeFruitX(mandiName)}</Text>
            </>
          ) : (
            <Text className={fontNoto}>{translate(title)}</Text>
          )}
        </div>
        <div />
      </TransactionHeader>
    </HeaderWrapper>
  );
};

export default Header;
