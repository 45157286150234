import { styled, Tab, Tabs } from "@mui/material";
import { colors } from "styles/AtomicStyles";

export const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    width: "35%",
    backgroundColor: colors.primary,
  },
});

export const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: "16px",
    marginRight: theme.spacing(1),
    color: colors.gray,
    "&.Mui-selected": {
      color: colors.primary,
      fontWeight: 700,
    },
  })
);
