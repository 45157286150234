import styled from "@emotion/styled";
import { useTheme } from "@mui/styles";
import { translate } from "locale/formatter";

const GradeBgConfig = {
  premium:
    "linear-gradient(90.25deg, #CD7532 0.22%, #FFA967 48.8%, #CD7532 98.4%)",
  super_a: "linear-gradient(90.3deg, #656565 8.16%, #323232 102.03%)",
  super_b: "linear-gradient(90.3deg, #3D3C3C 0.2%, #000000 102.03%)",
  royal:
    "linear-gradient(90deg, #D8AC12 18.75%, #F4DC5F 48.96%, #D17936 71.87%)",
  ultra_royal:
    " linear-gradient(90.3deg, #FFB800 0.2%, #EDE522 48.47%, #FBBD04 102.03%)",
};

const GradeTag = styled.div(({ type, colors }) => ({
  fontWeight: 600,
  color: colors.white,
  textAlign: "center",
  padding: "0.5rem 1rem",
  background: GradeBgConfig[type],
  textShadow: `0px 1px 2px ${colors.darkSilver}`,
}));

const GradeTypeTag = ({ type = "premium", className }) => {
  const { colors } = useTheme();
  return (
    <GradeTag
      colors={colors}
      type={type}
      className={`min-w-[72px] ${className}`}
    >
      {translate(type)}
    </GradeTag>
  );
};

export default GradeTypeTag;
